var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['program-category-item d-flex text-left row-gap-4 flex-column',
           'pb-3 pt-4 bg-neutral-n-2-background',
           _vm.isMobile ? 'shadows-line-down' : 'rounded']},[_c('div',{class:['cat-detail mx-3 mx-md-4 d-flex flex-column column-gap-xl-8 flex-xl-row',
             {'desktop': _vm.isDesktop}]},[_c('div',{staticClass:"left-col"},[(_vm.timeInterval)?_c('p',{class:['m-0 text-neutral-n-7-light-ink font-weight-bold text-nowrap',
                 _vm.isMobile ? 'paragraph-3' : _vm.isTablet ? 'paragraph-1' : 'large']},[_vm._v(" "+_vm._s(_vm.timeInterval)+" ")]):_vm._e()]),_c('div',{staticClass:"right-col d-flex flex-column row-gap-2"},[(_vm.name)?[(_vm.isMobile)?_c('h6',{staticClass:"m-0 text-neutral-n-7-light-ink font-weight-bold"},[_vm._v(" "+_vm._s(_vm.name)+" ")]):_c('h4',{staticClass:"m-0 text-neutral-n-7-light-ink font-weight-bold"},[_vm._v(" "+_vm._s(_vm.name)+" ")])]:_vm._e(),(_vm.description)?_c('div',[_c('p',{directives:[{name:"html-remove",rawName:"v-html-remove",value:(_vm.description),expression:"description"}],ref:"content",class:['desc label m-0 text-neutral-n-6-label',
                   _vm.isMobile ? 'label-3' : 'label-2',
                   {'ellipsis-3': !_vm.showAllDesc}]}),(_vm.showMore)?_c('span',{class:['label medium m-0 text-blue-b-3-primary-blue cursor-pointer',
                   _vm.isMobile ? 'label-3' : 'label-2' ],on:{"click":function($event){_vm.showAllDesc = !_vm.showAllDesc}}},[_vm._v(_vm._s(_vm.showAllDesc ? _vm.$t('actions.less') : _vm.$t('actions.more')))]):_vm._e()]):_vm._e()],2)]),_c('div',{staticClass:"sessions-dropdown"},[_c('div',{staticClass:"sessions-separator mx-3 mx-md-4 pb-4 pt-0 pt-md-4 d-flex align-items-center gap-4"},[_c('pill-widget',{attrs:{"hover":true,"label":("" + (_vm.$tc('program.sessions', 0, {count: _vm.sessions.length}))),"variant":_vm.variantEnum.WHITE,"size":"md"},on:{"on-click":function($event){_vm.isCollapsed = !_vm.isCollapsed}},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":("fa-regular fa-" + (!_vm.isCollapsed ? 'chevron-up' : 'chevron-down')),"container-classes":"pl-2","icon-classes":"icon-12"}})]},proxy:true}])}),_c('div',{staticClass:"separator w-100"})],1),_c('div',{class:['collapse-sessions d-flex flex-column', {'collapsed': _vm.isCollapsed}]},[(!_vm.isCollapsed)?_vm._l((_vm.sessions),function(s){return _c('program-session-item-component',_vm._g(_vm._b({key:s.uid,staticClass:"rounded",attrs:{"display-recommended-badge":_vm.displayRecommendedBadge,"selected-session":_vm.selectedSession}},'program-session-item-component',s,false),_vm.$listeners))}):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }