export default {
  article: () => import('@/components/cards/ArticleCardWidget.vue'),
  boatProduct: () => import('@/components/cards/BoatProductCardWidget.vue'),
  channel: () => import('@/components/cards/ChannelCard.vue'),
  company: () => import('@/components/cards/CompanyCardWidget.vue'),
  companyContact: () => import('@/components/cards/CompanyContactCard.vue'),
  crmUser: () => import('@/components/cards/CrmUserCardWidget.vue'),
  deal: () => import('@/components/cards/DealCardWidget.vue'),
  event: () => import('@/components/cards/EventCardWidget.vue'),
  events: () => import('@/components/cards/EventCardWidget.vue'),
  exhibitor: () => import('@/components/cards/ExhibitorCardWidget.vue'),
  exhibitorVideo: () => import('@/components/cards/CompanyVideoCard.vue'),
  information: () => import('@/components/cards/InformationCardComponent.vue'),
  largeProduct: () => import('@/components/cards/LargeProductCardWidget.vue'),
  member: () => import('@/components/cards/UserCardWidget.vue'),
  salesPackage: () => import('@/components/cards/PackageCard.vue'),
  package: () => import('@/components/cards/PackageCard.vue'),
  product: () => import('@/components/cards/ProductCardWidget.vue'),
  session: () => import('@/components/cards/SessionCardWidget.vue'),
  speaker: () => import('@/components/cards/SpeakerCardWidget.vue'),
  subEdition: () => import('@/components/cards/EventCardWidget.vue'),
  tag: () => import('@/components/cards/ArticleCardWidget.vue'),
  topic: () => import('@/components/cards/TopicCardWidget.vue'),
  videoSession: () => import('@/components/cards/LiveContentCard.vue'),
};
