

































































import { Component, Prop, Watch } from 'vue-property-decorator';
import Meeting from '@/models/graphql/Meeting';
import { State } from 'vuex-class';
import {
  differenceInMinutes,
  eachDayOfInterval,
  endOfWeek,
  format,
  getDay,
  setHours,
  startOfDay,
  startOfWeek,
} from 'date-fns';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { utcToZonedTime } from 'date-fns-tz';
import MeetingSlotComponent from '@/components/full-calendar/MeetingSlotComponent.vue';

type WeeklyCalendarType = {
  top: number;
  left: number;
  width: number;
  height: number;
  meeting: Meeting;
}

@Component({
  components: { MeetingSlotComponent },
})
export default class WeeklyCalendarComponent extends BreakpointWrapper {
  @Prop({ default: () => new Date() })
  private date!: Date;

  @Prop({ default: () => [] })
  private meetings!: Meeting[];

  @Prop({ default: null })
  private activeMeeting!: string;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  private cellHeight = 142;

  private cellWidth = 142;

  private calendarHeight = 'calc(100dvh - 8rem)';

  private cellSize = {
    width: '159px',
    height: `${this.cellHeight}px`,
  };

  private calendarPosition = {
    top: '52px',
    left: '81px',
  };

  private calendarRecord: WeeklyCalendarType[] = [];

  private get weekdays(): Array<{ d: string; m: string }> {
    const firstDayOfWeek = startOfWeek(this.date, { locale: this.dateLocale });
    return eachDayOfInterval({
      start: firstDayOfWeek,
      end: endOfWeek(firstDayOfWeek, { locale: this.dateLocale }),
    })
      .map((day) => ({
        d: format(
          day,
          `${this.$t('app.date.dayOfWeek')}`,
          { locale: this.dateLocale },
        ),
        m: format(
          day,
          `${this.$t('app.date.monthDayShort')}`,
          { locale: this.dateLocale },
        ),
      }));
  }

  private get hours(): string[] {
    const firstDayOfDay = startOfDay(new Date());
    return [...Array(24)
      .keys()]
      .map((hour) => format(
        setHours(firstDayOfDay, hour),
        `${this.$t('app.date.defaultTimeFormat')}`,
        { locale: this.dateLocale },
      )
        .replace(':00 ', ''));
  }

  mounted(): void {
    this.calcCellSize();
    window.addEventListener('resize', this.calcCellSize);
  }

  private initCalendarRecord(): void {
    this.calendarRecord = [];
    this.meetings.forEach((m) => {
      const startTime = utcToZonedTime(`${m.startTime}Z`, this.selectedTzName);
      const endTime = utcToZonedTime(`${m.endTime}Z`, this.selectedTzName);
      const start = differenceInMinutes(startTime, startOfDay(startTime));
      const end = differenceInMinutes(endTime, startTime);
      const dayOfWeek = getDay(startTime);
      this.calendarRecord.push({
        meeting: m,
        top: (this.cellHeight * (start / 60)) - 4,
        left: this.cellWidth * dayOfWeek,
        width: this.cellWidth,
        height: this.cellHeight * (end / 60),
      });
    });
    this.arrangeCalSideBySide();
    const calendarMeetingEl = document.querySelector('.weekly-calendar');
    if (calendarMeetingEl) {
      let initScrollPosition = this.cellHeight * 6;
      if (this.calendarRecord.length > 0) {
        initScrollPosition = this.calendarRecord[0].top - this.cellHeight;
      }
      calendarMeetingEl.scrollTo({ top: initScrollPosition });
    }
  }

  private arrangeCalSideBySide(): void {
    this.calendarRecord.sort((a, b) => a.top - b.top);
    let currentRow = 0;
    const rows: WeeklyCalendarType[][] = [];

    this.calendarRecord.forEach((currentCal, index) => {
      if (index === 0) {
        rows.push([currentCal]);
      } else if (
        this.isColliding(currentCal, this.calendarRecord[index - 1])
      ) {
        rows[currentRow].push(currentCal);
      } else {
        currentRow += 1;
        rows.push([currentCal]);
      }
    });
    this.adjustCalWidths(rows, this.cellWidth);
  }

  private isColliding = (object1: WeeklyCalendarType, object2: WeeklyCalendarType): boolean => {
    if (object1.left >= object2.left + object2.width) {
      return false;
    }
    if (object1.left + object1.width <= object2.left) {
      return false;
    }
    if (object1.top >= object2.top + object2.height) {
      return false;
    }
    return object1.top + object1.height > object2.top;
  };

  private adjustCalWidths(rows: WeeklyCalendarType[][], availableWidth: number): void {
    this.calendarRecord = rows.map((row) => {
      const space = 4;
      const rowWidth = row.reduce((sum, div) => sum + div.width, 0);
      const scaleFactor = availableWidth / rowWidth;
      return row.map((cal, index) => {
        let { left } = cal;
        let { width } = cal;
        if (scaleFactor < 1) {
          left = cal.left + (cal.width * scaleFactor * index) + (space / row.length);
          width = (cal.width * scaleFactor) - space + (space / row.length);
        }
        return {
          ...cal,
          left,
          width,
        };
      });
    })
      .flat();
  }

  @Watch('meetings')
  private calcCellSize(): void {
    const el = document.querySelector('.weekly-calendar');
    const elHour = document.querySelector('.calendar-cell.hour');
    if (el && elHour) {
      this.calendarPosition.left = `${elHour.getBoundingClientRect().width}px`;
      this.calendarHeight = `calc(100dvh - ${el.getBoundingClientRect().top}px)`;
      if (this.isMobile) {
        this.cellSize = {
          width: '95px',
          height: `${this.cellHeight}px`,
        };
      } else {
        this.cellSize = {
          width: '159px',
          height: `${this.cellHeight}px`,
        };
      }
    }
    const elCell = document.querySelector('.header .calendar-cell:not(.fixed-cell)');
    if (elCell) {
      this.cellWidth = elCell.getBoundingClientRect().width;
    }
    this.initCalendarRecord();
  }
}
