












































































































































































































































































































import { Component, Prop } from 'vue-property-decorator';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { Getter, namespace, State } from 'vuex-class';
import ProgramEntitySearchWidgetHelper
  from '@/utils/helpers/widgets/ProgramEntitySearchWidgetHelper';
import PillWidget from '@/components/pill/PillWidget.vue';
import Category from '@/models/graphql/Category';
import Exhibitor from '@/models/graphql/Exhibitor';
import SponsorRole from '@/models/graphql/SponsorRole';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import SpeakerRole from '@/models/graphql/SpeakerRole';
import Geozone from '@/models/graphql/Geozone';
import { CommunityUserAgendaEntryFilter } from '@/graphql/_Filters/CommunityUserAgendaEntryFilter';
import CommunityUserAgendaEntry from '@/models/graphql/CommunityUserAgendaEntry';
import Session from '@/models/graphql/Session';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Event from '@/utils/types/Event';
import GUUID from '@/utils/GUUID';
import ManageConflictModal from '@/components/program/ManageConflictModal.vue';
import { utcToZonedTime } from 'date-fns-tz';
import { getHours } from 'date-fns';
import RecommendationBadgeComponent from '@/components/RecommendationBadgeComponent.vue';
import * as StringHelper from '@/utils/helpers/StringHelper';

const communityUserAgendaEntryStore = namespace('CommunityUserAgendaEntryStore');
/* eslint-disable no-underscore-dangle */
@Component({
  components: {
    RecommendationBadgeComponent,
    ManageConflictModal,
    ButtonComponent,
    ButtonIconComponent,
    FontAwesomeComponent,
    AvatarSoloWidget,
    PillWidget,
  },
})
export default class ProgramSessionItemComponent extends BreakpointWrapper {
  @Prop({
    required: false,
    default: null,
  })
  private readonly uid!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly name!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly description!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly startTime!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly startTimestamp!: number;

  @Prop({
    required: false,
    default: null,
  })
  private readonly endTime!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly endTimestamp!: number;

  @Prop({
    required: false,
    default: null,
  })
  private readonly selectedSession!: string | null;

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly categoriesInContext!: Category[];

  @Prop({
    required: false,
    default: null,
  })
  private readonly exhibitor!: Exhibitor;

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly sponsorRoles!: SponsorRole[];

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly speakerRoles!: SpeakerRole[];

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly geozonesOfEdition!: Geozone[];

  @Prop({
    required: false,
    default: null,
  })
  private readonly _isInAgenda!: string | null;

  @Prop({
    required: false,
    default: false,
  })
  private readonly _haveConflicts!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly _isRecommendedForMe!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private readonly featured!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly disableLeftRightBorder!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private readonly displayRecommendedBadge!: boolean;

  @State
  private selectedTzAbbreviation!: string;

  @State
  private selectedTzName!: string;

  @State
  private dateLocale!: Locale;

  @State
  private authUser!: CommunityUser;

  @State
  private community!: Community;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @communityUserAgendaEntryStore.Action
  private addToAgenda!: (
    payload: CommunityUserAgendaEntryFilter,
  ) => Promise<CommunityUserAgendaEntry | undefined>;

  @communityUserAgendaEntryStore.Action
  private removeFromAgenda!: (
    payload: CommunityUserAgendaEntryFilter,
  ) => Promise<CommunityUserAgendaEntry | undefined>;

  @communityUserAgendaEntryStore.Action
  private checkForConflicts!: (
    payload: { currentEntity: string; start: number; end: number },
  ) => Promise<Event[]>;

  private FileResourceHelper = FileResourceHelper;

  private FeatureKeys = FeatureKeys;

  private visibleCategories: Category[] = this.categoriesInContext;

  private hiddenCategoriesCount = 0;

  private displayedSpeakers = '';

  private inAgenda = this._isInAgenda;

  private haveConflict = this._haveConflicts;

  private isAgendaLoading = false;

  private modalId = `manage-conflicts-modal-${GUUID.uuidv4()}`;

  private events: Event[] = [];

  private contentWidth = 0;

  private get selectedDay(): string {
    return ProgramEntitySearchWidgetHelper.formatTime(this.startTimestamp, this.selectedTzName, this.dateLocale, 'yyyy-MM-dd');
  }

  private get selectedHour(): number {
    return getHours(utcToZonedTime(this.startTimestamp * 1000, this.selectedTzName));
  }

  private get timeInterval(): string {
    const parts: string[] = [];
    if (this.startTimestamp) {
      parts.push(ProgramEntitySearchWidgetHelper.formatTime(this.startTimestamp, this.selectedTzName, this.dateLocale, 'h:mm a'));
    }
    if (this.endTimestamp && this.endTimestamp > this.startTimestamp) {
      parts.push(ProgramEntitySearchWidgetHelper.formatTime(this.endTimestamp, this.selectedTzName, this.dateLocale, 'h:mm a'));
    }
    return parts.join(' – ');
  }

  private get speakers(): string[] {
    if (this.speakerRoles.length > 0) {
      const names: string[] = [];
      this.speakerRoles
        .forEach((speakerRole) => {
          const { speaker } = speakerRole;
          const fullName = [];
          if (speaker.prefix) {
            fullName.push(speaker.prefix);
          }
          fullName.push(`${speakerRole.speaker.firstName} ${speakerRole.speaker.lastName}`.trim());
          if (speaker.suffix) {
            fullName.push(speaker.suffix);
          }
          if (fullName.length > 0) {
            names.push(fullName.join(' '));
          }
        });
      return names;
    }
    return [];
  }

  private get sponsorImages(): Array<{ uid: string; src: string; alt: string }> {
    return this.sponsorRoles
      .map((sr) => ({
        uid: sr.sponsor.uid,
        src: FileResourceHelper.getFullPath(sr.sponsor.logoFileResource, 'w256'),
        alt: sr.sponsor.name || '',
      }));
  }

  private get hall(): string {
    if (this.geozonesOfEdition
      && this.geozonesOfEdition.length > 0
      && this.geozonesOfEdition[0]
    ) {
      const txt: string[] = [];
      if (this.geozonesOfEdition[0].name) {
        txt.push(this.geozonesOfEdition[0].name);
      }
      if (this.geozonesOfEdition[0].exhibitHall
        && this.geozonesOfEdition[0].exhibitHall.name) {
        txt.push(StringHelper.formatStringToWords(this.geozonesOfEdition[0].exhibitHall.name));
      }
      return txt.join(' · ');
    }
    return '';
  }

  mounted(): void {
    this.calculateVisibility();
    setTimeout(() => {
      if (this.$el && this.selectedSession === this.uid) {
        this.$el.scrollIntoView({
          block: 'center',
          inline: 'center',
        });
      }
    }, 300);
    window.addEventListener('resize', this.calculateVisibility);
  }

  beforeDestroy(): void {
    window.removeEventListener('resize', this.calculateVisibility);
  }

  scheduleConflict(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    this.isAgendaLoading = true;
    this.checkForConflicts({
      currentEntity: this.uid,
      start: this.startTimestamp,
      end: this.endTimestamp,
    })
      .then((events) => {
        if (events.length > 0) {
          this.events = events.filter((event) => event.uid !== this.uid);
          this.$bvModal.show(this.modalId);
        } else {
          this.addSessionToAgenda();
          this.haveConflict = false;
        }
        this.isAgendaLoading = false;
      });
  }

  addSessionToAgenda(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }

    if (!this.inAgenda) {
      const event = AgendaStoreHelper.convertSessionToEvent(this.$props as Session, this.selectedTzAbbreviation);
      this.addToAgenda({
        event,
        userId: this.authUser.uid,
        linkedUserId: event.entityId,
        entityType: event.entityType,
        showToast: true,
      })
        .then((response) => {
          this.inAgenda = response?.uid || '';
          this.$emit('set-is-agenda', this.uid, null, response?.uid);
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code as string,
            event.entityType,
            StatLoggerActions.ADD_TO_AGENDA,
            'addAppointment',
            -1,
            event.entityId,
            StatLoggerCategories.ADD,
            this.$i18n.locale,
          );
        })
        .finally(() => {
          this.isAgendaLoading = false;
        });
    }
  }

  removeSessionFromAgenda(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }

    if (this.inAgenda) {
      const event = AgendaStoreHelper.convertSessionToEvent(this.$props as Session, this.selectedTzAbbreviation);
      this.removeFromAgenda({
        event,
        uid: this.inAgenda,
        showToast: true,
      })
        .then(() => {
          this.inAgenda = null;
          this.$emit('set-is-agenda', this.uid, null, null);
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code as string,
            event.entityType,
            StatLoggerActions.REMOVE_FROM_AGENDA,
            'removeAppointment',
            -1,
            event.entityId,
            StatLoggerCategories.REMOVE,
            this.$i18n.locale,
          );
        })
        .finally(() => {
          this.isAgendaLoading = false;
        });
    }
  }

  private setContentWidth(): void {
    if (!this.$refs.informationElement) {
      return;
    }
    const el = this.$refs.informationElement as HTMLElement;
    const style = window.getComputedStyle(el);
    const paddingLeft = parseFloat(style.paddingLeft);
    const paddingRight = parseFloat(style.paddingRight);
    this.contentWidth = el.offsetWidth - paddingLeft - paddingRight;
  }

  private calculateVisibility(): void {
    this.$nextTick(() => {
      this.setContentWidth();
      this.calculateVisibleCategories();
      this.calculateVisibleSpeakers();
    });
  }

  private calculateVisibleCategories(): void {
    const container = this.$refs.categoriesContainer as HTMLElement;
    if (!container) return;

    const children = Array.from(container.children) as HTMLElement[];
    const containerWidth = this.contentWidth - (this.isMobile ? 16 : 24);
    let totalWidth = 0;
    const visibleItems: Category[] = [];
    let countHidden = 0;

    for (let i = 0; i < this.categoriesInContext.length; i++) {
      const child = children[i];
      if (!child) break;

      totalWidth = totalWidth + child.offsetWidth + (this.isMobile ? 4 : 8);

      if (totalWidth <= containerWidth) {
        visibleItems.push(this.categoriesInContext[i]);
      } else {
        countHidden = this.categoriesInContext.length - visibleItems.length;
        break;
      }
    }

    this.visibleCategories = visibleItems;
    this.hiddenCategoriesCount = countHidden;
  }

  private calculateVisibleSpeakers(): void {
    const speakersLabel = this.$refs.speakersLabel as HTMLElement;
    if (!speakersLabel) return;

    this.displayedSpeakers = '';
    const tempSpeakers = [...this.speakers]; // Copy the speakers list
    let hiddenCount = 0;
    let fits = false;

    const tempSpan = document.createElement('span');
    tempSpan.style.visibility = 'hidden';
    tempSpan.style.position = 'absolute';
    tempSpan.style.whiteSpace = 'nowrap';
    tempSpan.classList.add(...['label', 'label-3', 'medium', 'm-0']);
    document.body.appendChild(tempSpan);
    const availableSpace = this.contentWidth - speakersLabel.offsetWidth - 8;
    while (tempSpeakers.length > 0) {
      tempSpan.innerText = tempSpeakers.join(', ') + (hiddenCount > 0 ? `, +${hiddenCount} ${this.$t('program.more')}` : '');

      if (tempSpan.offsetWidth > availableSpace) {
        hiddenCount += 1;
        tempSpeakers.pop(); // Remove last speaker
      } else {
        fits = true;
        break; // Fits within width
      }
    }

    // If no full names fit, truncate the first one
    if (!fits && this.speakers.length > 0) {
      const truncatedFirstSpeaker = this.truncateText(
        this.speakers[0],
        availableSpace,
        `, +${hiddenCount} ${this.$t('program.more')}`,
      );
      this.displayedSpeakers = `${truncatedFirstSpeaker}, +${this.speakers.length - 1} ${this.$t('program.more')}`;
    } else {
      this.displayedSpeakers = tempSpeakers.join(', ') + (hiddenCount > 0 ? `, +${hiddenCount} ${this.$t('program.more')}` : '');
    }

    document.body.removeChild(tempSpan);
  }

  // eslint-disable-next-line class-methods-use-this
  private truncateText(text: string, maxWidth: number, alt: string): string {
    const tempSpan = document.createElement('span');
    tempSpan.style.visibility = 'hidden';
    tempSpan.style.position = 'absolute';
    tempSpan.style.whiteSpace = 'nowrap';
    tempSpan.classList.add(...['label', 'label-3', 'medium', 'm-0']);
    document.body.appendChild(tempSpan);

    let truncatedText = text;
    for (let i = text.length; i > 0; i--) {
      tempSpan.innerText = `${text.substring(0, i)}...${alt}`;
      if (tempSpan.offsetWidth < maxWidth) {
        truncatedText = `${text.substring(0, i)}...`;
        break;
      }
    }

    document.body.removeChild(tempSpan);
    return truncatedText;
  }

  private toggleAgenda(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }

    if (this.inAgenda) {
      this.removeSessionFromAgenda();
    } else {
      this.scheduleConflict();
    }
  }
}
