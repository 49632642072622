
























































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import BootstrapBreakpointsLabels from '@/utils/enums/BootstrapBreakpointsLabels';
import InputText from '@/components/InputText.vue';
import { Getter, namespace } from 'vuex-class';
import Handout from '@/models/graphql/Handout';
import UploadAssetComponent from '@/components/UploadAssetComponent.vue';
import FileType from '@/utils/enums/FileType';
import UploadFileHandler from '@/utils/UploadFileHandler';
import Community from '@/models/graphql/Community';
import FileResource from '@/models/graphql/FileResource';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import EntityType from '@/utils/enums/EntityType';
import FileResourceParams from '@/utils/types/FileResourceParams';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

const fileResourceStore = namespace('FileResourceStore');
const handoutStore = namespace('HandoutStore');
const toastStore = namespace('ToastStore');

@Component({
  data(): object {
    return {
      name: '',
      oldHandout: null,
      newHandout: null,
      currentHandout: null,
      newHandoutName: '',
      handoutPayloadTooLarge: false,
      uploadingHandout: false,
    };
  },
  components: {
    FontAwesomeComponent,
    UploadAssetComponent,
    ButtonComponent,
    InputText,
    ButtonIconComponent,
    StandardModal,
  },
})
export default class HandoutEditModal extends Vue {
  @Prop({ default: '' })
  modalId!: string;

  @Getter
  private community!: Community;

  @Prop()
  private readonly currentBootstrapBreakpoint!: BootstrapBreakpointsLabels;

  @Prop({ required: false, default: null })
  private readonly handout!: Handout;

  @Prop({ required: false, default: '' })
  private readonly entityId!: string;

  @Prop({ required: false, default: EntityType.EXHIBITOR })
  private readonly entityType!: string;

  @Prop({ required: false, default: null })
  private readonly handoutType!: string;

  @handoutStore.Action
  private createOrUpdateHandoutForEntity!: (payload: {
    entityId: string;
    entityType: string;
    handout: Partial<Handout>;
  }) => Promise<Handout | undefined>;

  @fileResourceStore.Action
  private createFileResourceForHandout!: (payload: {
    entityId: string;
    fileResourceData: FileResourceParams;
  }) => Promise<FileResource | undefined>;

  @fileResourceStore.Action
  private deleteFileResource!: (uid: string) => Promise<FileResource | undefined>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private handoutToEdit: Handout | null = null;

  private isSaveBtnDisabled = true;

  private saveLoading = false;

  private resetChildUpload = 0;

  private fileType = `${FileType.PDF},${FileType.DOC},${FileType.DOCX},${FileType.XLS},
  ${FileType.XLSX},${FileType.PPT},${FileType.PPTX}`;

  private get isMobile(): boolean {
    return this.currentBootstrapBreakpoint
      && (this.currentBootstrapBreakpoint === 'sm' || this.currentBootstrapBreakpoint === 'xs');
  }

  mounted(): void {
    this.handoutToEdit = JSON.parse(JSON.stringify(this.handout));
    this.setData();
  }

  @Watch('handout')
  private updateHandout(): void {
    this.handoutToEdit = JSON.parse(JSON.stringify(this.handout));
    this.setData();
  }

  private setData(): void {
    if (this.handoutToEdit) {
      this.$data.name = this.handoutToEdit.name;
      this.$data.oldHandout = this.handoutToEdit.handoutFileResource
        ? this.handoutToEdit.handoutFileResource
        : null;
      this.$data.currentHandout = this.handoutToEdit.handoutFileResource
        ? this.handoutToEdit.handoutFileResource
        : null;
      this.isSaveBtnDisabled = this.handoutToEdit.name === '';
    } else {
      this.$data.name = '';
      this.$data.oldHandout = null;
      this.$data.currentHandout = null;
      this.$data.newHandout = null;
      this.$data.newHandoutName = '';
      this.isSaveBtnDisabled = true;
    }
  }

  @Watch('$data.name')
  @Watch('$data.currentHandout')
  private nameFieldUpdate(): void {
    this.isSaveBtnDisabled = !this.$data.name
      || this.$data.name === ''
      || !this.$data.currentHandout;
  }

  private onConfirm(): void {
    this.saveLoading = true;
    const handout = {
      uid: this.handout ? this.handout.uid : undefined,
      name: this.$data.name,
      type: this.handoutType ? this.handoutType : this.entityType,
    };
    this.createOrUpdateHandoutForEntity({
      entityId: this.entityId,
      entityType: this.entityType,
      handout,
    }).then((response) => {
      if (response) {
        let uid = '';
        if (response.uid) {
          uid = response.uid;
        }
        if (this.community.code) {
          if (this.$data.newHandout) {
            UploadFileHandler.uploadFileResourceHandler(
              {
                base64Path: this.$data.newHandout,
                fileName: this.$data.newHandoutName,
                schemaCode: this.community.code,
              },
            ).then((request) => {
              // eslint-disable-next-line no-param-reassign
              request.onload = () => {
                if (request?.status === 413) {
                  this.$data.handoutPayloadTooLarge = true;
                  this.$data.uploadingHandout = false;
                } else {
                  this.$data.handoutPayloadTooLarge = false;
                  this.$data.uploadingHandout = false;
                  if (this.handoutToEdit && this.handoutToEdit.handoutFileResource) {
                    this.deleteFileResource(this.handoutToEdit.handoutFileResource.uid);
                  }
                  const uploadToken = JSON.parse(request.response)?.uploadToken;
                  if (uploadToken) {
                    this.createFileResourceForHandout({
                      entityId: uid,
                      fileResourceData: {
                        uploadToken,
                        resourceType: 'HANDOUT_IMAGE',
                        fileType: 'HANDOUT_IMAGE',
                      },
                    }).then((result) => {
                      if (result) {
                        if (this.handout) {
                          this.showToast(ToastActionType.UPDATE_HANDOUT);
                        } else {
                          this.showToast(ToastActionType.CREATE_HANDOUT);
                        }
                      } else {
                        this.showToast(ToastActionType.GENERIC_ERROR_MESSAGE);
                      }
                      this.saveLoading = false;
                      this.onSaveEnd();
                    });
                  }
                }
              };
            });
          } else if (!this.$data.currentHandout) {
            if (this.handout) {
              this.showToast(ToastActionType.UPDATE_HANDOUT);
            } else {
              this.showToast(ToastActionType.CREATE_HANDOUT);
            }
            if (this.handoutToEdit && this.handoutToEdit.handoutFileResource) {
              this.deleteFileResource(this.handoutToEdit.handoutFileResource.uid);
            }
            this.saveLoading = false;
            this.onSaveEnd();
          } else {
            if (this.handout) {
              this.showToast(ToastActionType.UPDATE_HANDOUT);
            } else {
              this.showToast(ToastActionType.CREATE_HANDOUT);
            }
            this.saveLoading = false;
            this.onSaveEnd();
          }
        }
      }
    });
  }

  private onFileUpload({ readerResult, fileName }:
                         { readerResult: string | ArrayBuffer | null; fileName: string }): void {
    this.$data.currentHandout = readerResult;
    this.$data.newHandout = readerResult;
    this.$data.newHandoutName = fileName;
  }

  private onSaveEnd(): void {
    this.$emit('on-update-end');
    this.$bvModal.hide(this.modalId);
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
  }

  private onDelete(): void {
    this.$emit('on-delete', {
      handoutId: this.handout.uid,
    });
  }

  private onShow(): void {
    this.handoutToEdit = JSON.parse(JSON.stringify(this.handout));
    this.setData();
  }

  private onHide(): void {
    this.$emit('on-modal-hide');
    this.resetChildUpload += 1;
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }
}
