






















































































































































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import FilterItemComponent from '@/components/FilterItemComponent.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import BootstrapBreakpointsLabels from '@/utils/enums/BootstrapBreakpointsLabels';
import InputText from '@/components/InputText.vue';
import CategorySelectorComponent from '@/components/CategorySelectorComponent.vue';
import DateRadioFieldComponent from '@/components/DateRadioFieldComponent.vue';
import { Getter, namespace, State } from 'vuex-class';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import UploadAssetComponent from '@/components/UploadAssetComponent.vue';
import FileType from '@/utils/enums/FileType';
import FileResource from '@/models/graphql/FileResource';
import UploadFileHandler from '@/utils/UploadFileHandler';
import Community from '@/models/graphql/Community';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import Exhibitor from '@/models/graphql/Exhibitor';
import EntityTranslationParams from '@/utils/types/EntityTranslationParams';
import Session from '@/models/graphql/Session';
import LocaleModel from '@/models/LocaleModel';
import PickerDateTimeComponent from '@/components/calendar/PickerDateTimeComponent.vue';
import Category from '@/models/graphql/Category';
import TextEditorComponent from '@/components/TextEditorComponent.vue';

const fileResourceStore = namespace('FileResourceStore');
const sessionStore = namespace('SessionStore');
const toastStore = namespace('ToastStore');
const exhibitorStore = namespace('ExhibitorStore');
const categoryStore = namespace('CategoryStore');

@Component({
  data(): object {
    return {
      name: '',
      oldCategories: [],
      newCategories: [],
      description: '',
      price: '',
      speakers: '',
      activityType: '',
      oldDisplayFileResource: null,
      newDisplayFileResource: null,
      startTime: null,
      endTime: null,
    };
  },
  components: {
    TextEditorComponent,
    PickerDateTimeComponent,
    UploadAssetComponent,
    DateRadioFieldComponent,
    CategorySelectorComponent,
    InputText,
    FontAwesomeComponent,
    StandardModal,
    PillWidget,
    FilterItemComponent,
    InputSearchComponent,
    ButtonIconComponent,
    ButtonComponent,
  },
})
export default class SessionEditModal extends Vue {
  @Prop({ default: '' })
  modalId!: string;

  @Getter
  private community!: Community;

  @Prop()
  private readonly currentBootstrapBreakpoint!: BootstrapBreakpointsLabels;

  @Prop({ required: false, default: null })
  private readonly session!: Session;

  @Prop({ required: false, default: '' })
  private readonly fields!: string;

  @Prop({ required: false, default: '' })
  private readonly exhibitorId!: string;

  @exhibitorStore.Getter('fetchAdminPanelExhibitor')
  private adminPanelExhibitor!: Exhibitor;

  @sessionStore.Action
  private editSession!: (payload: {
    exhibitorId: string;
    session: object;
  }) => Promise<Session | undefined>;

  @sessionStore.Action
  private updateSessionCategories!: (payload: { session: object }) => Promise<void>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @fileResourceStore.Action
  private deleteFileResource!: (uid: string) => Promise<FileResource | undefined>;

  @fileResourceStore.Action
  private createBannerFileResourceForSession!: (data: {
    uid: string;
    uploadToken: string;
    fileName: string;
    fileType: string;
  }) => Promise<FileResource | undefined>;

  @sessionStore.Action
  private updateSessionByLocale!: (payload: {
    session: {
      uid: string;
      translations: Record<string, string>;
    };
  }) => Promise<Session | undefined>;

  @categoryStore.Getter
  private fetchProductCategoryForCmsTable!: Category;

  @State
  private readonly locales!: LocaleModel[];

  @State
  private selectedTzName!: string;

  private sessionToEdit: Session | null = null;

  private updatedTranslations: Record<string, string> = {};

  private isSaveBtnDisabled = true;

  private FileType = FileType;

  private fieldTable: string[] = [];

  private saveLoading = false;

  private editorConfigs = {
    readOnly: false,
    theme: 'snow',
    placeholder: '',
    formats: [
      'bold',
      'italic',
      'underline',
      'list',
    ],
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', { list: 'ordered' }, { list: 'bullet' }],
      ],
      clipboard: {
        matchVisual: false,
      },
    },
  };

  private get formatDescription(): string {
    if (!this.$data.description) {
      return '';
    }

    const htmlRegex = /<?[a-z][\s\S]*>.*<\/[a-z][\s\S]*>/i;

    if (htmlRegex.test(this.$data.description)) {
      return this.$data.description;
    }
    let formattedText = '';
    const lines = this.$data.description.split('\n');
    lines.forEach((line: string) => {
      if (line === '') {
        formattedText += '<p><br></p>';
      } else {
        formattedText += `<p>${line}</p>`;
      }
    });
    return formattedText;
  }

  private get isMobile(): boolean {
    return this.currentBootstrapBreakpoint && ['xs', 'sm'].includes(this.currentBootstrapBreakpoint);
  }

  private get nameTranslations(): EntityTranslationParams[] {
    if (this.sessionToEdit && this.sessionToEdit.uid !== '' && this.locales && this.locales.length > 1) {
      return this.sessionToEdit.translationsByField('name');
    }
    return this.locales ? this.locales.map((l) => ({
      locale: l.locale || '',
      value: '',
      field: 'name',
    })) : [];
  }

  private get descriptionTranslations(): EntityTranslationParams[] {
    if (this.sessionToEdit && this.sessionToEdit.uid !== '' && this.locales && this.locales.length > 1) {
      return this.sessionToEdit.translationsByField('description');
    }
    return this.locales ? this.locales.map((l) => ({
      locale: l.locale || '',
      value: '',
      field: 'description',
    })) : [];
  }

  created(): void {
    this.updateSession();
  }

  @Watch('session')
  private updateSession(): void {
    this.sessionToEdit = Session.hydrate(this.session);
    this.setData();
  }

  private setDisplayFileResource(file: { readerResult: string; fileName: string } | null): void {
    this.$data.newDisplayFileResource = file;
  }

  private setData(): void {
    const now = DateTimeHelper.zonedToUTCTimeDate(DateTimeHelper.nowInZonedTime(this.selectedTzName), this.selectedTzName);
    this.$data.name = '';
    this.$data.description = '';
    this.$data.price = '';
    this.$data.speakers = '';
    this.$data.activityType = '';
    this.$data.startTime = DateTimeHelper.toISO8601(now);
    this.$data.endTime = DateTimeHelper.toISO8601(now);
    this.$data.oldDisplayFileResource = null;
    this.$data.newDisplayFileResource = null;
    this.$data.newCategories = [];
    this.$data.oldCategories = [];
    this.isSaveBtnDisabled = true;

    if (this.fields) {
      this.fieldTable = this.fields.split(',');
    }

    if (this.sessionToEdit && !!this.sessionToEdit.uid) {
      this.$data.name = this.sessionToEdit.name;
      this.$data.description = this.sessionToEdit.description;
      this.$data.price = this.sessionToEdit.price;
      this.$data.speakers = this.sessionToEdit.speakers;
      this.$data.activityType = this.sessionToEdit.activityType;
      this.$data.startTime = this.sessionToEdit.startTime;
      this.$data.endTime = this.sessionToEdit.endTime;
      this.$data.newDisplayFileResource = this.sessionToEdit.bannerFileResource;
      this.$data.oldDisplayFileResource = this.sessionToEdit.bannerFileResource;
      this.$data.newCategories = this.sessionToEdit.categories
        ? [...this.sessionToEdit.categories]
        : [];
      this.$data.oldCategories = this.sessionToEdit.categories
        ? [...this.sessionToEdit.categories]
        : [];
      this.isSaveBtnDisabled = this.sessionToEdit.name === ''
         || this.sessionToEdit.startTime === '' || this.sessionToEdit.endTime === '';
    }
  }

  private onCategoryUpdate(payload: {
    ids?: string[];
  }): void {
    this.$data.newCategories = payload.ids;
  }

  @Watch('$data.name')
  private nameFieldUpdate(): void {
    this.isSaveBtnDisabled = !this.$data.name || this.$data.name === '';
  }

  private onStartTimeChange(data: { date: Date; timestamp: number; text: string }): void {
    this.$data.startTime = data.text;
  }

  private onEndTimeChange(data: { date: Date; timestamp: number; text: string }): void {
    this.$data.endTime = data.text;
  }

  private zonedTime(date: string): Date {
    if (!date) {
      return DateTimeHelper.toUTC(DateTimeHelper.nowInZonedTime(this.selectedTzName));
    }
    return DateTimeHelper.utcToZonedTimeDate(
      `${date}Z`,
      this.selectedTzName,
    );
  }

  private fieldEnable(field: string): boolean {
    if (this.fieldTable.length > 0) {
      return this.fieldTable.includes(field);
    }
    return true;
  }

  private updateTranslations(field: string, translation: EntityTranslationParams): void {
    if (this.locales && this.locales.length > 1 && this.sessionToEdit) {
      let oldTranslations: EntityTranslationParams[] = [];
      if (field === 'name') {
        oldTranslations = this.sessionToEdit.translationsByField('name');
        if (translation.locale === this.$i18n.locale) {
          this.$data.name = translation.value || '';
        }
      }
      if (field === 'description') {
        oldTranslations = this.sessionToEdit.translationsByField('description');
        if (translation.locale === this.$i18n.locale) {
          this.$data.description = translation.value || '';
        }
      }
      const trans = oldTranslations.find((oldTrans) => translation.locale === oldTrans.locale);
      if (!trans || trans.value !== translation.value) {
        Object.assign(this.updatedTranslations, { [`${translation.field}-${translation.locale}`]: translation.value });
      } else {
        delete this.updatedTranslations[`${translation.field}-${translation.locale}`];
      }
    }
  }

  private onConfirm(): void {
    this.saveLoading = true;
    let uid = '';
    const translations: Record<string, string> = {};
    Object.keys(this.updatedTranslations).forEach((key) => {
      if (!key.includes(this.$i18n.locale)) {
        Object.assign(translations, { [key]: this.updatedTranslations[key] || '' });
      }
    });
    const session = {
      uid: this.session ? this.session.uid : null,
      name: this.$data.name,
      description: this.$data.description,
      activityType: 'exhibitor_event',
      price: this.$data.price,
      speakers: this.$data.speakers,
      startTime: this.$data.startTime,
      endTime: this.$data.endTime,
      oldCategories: this.$data.oldCategories,
      newCategories: this.$data.newCategories,
    };
    if (this.fetchProductCategoryForCmsTable.domain) {
      session.newCategories.push(this.fetchProductCategoryForCmsTable);
    }
    this.editSession({
      exhibitorId: this.exhibitorId,
      session,
    }).then((response) => {
      if (response) {
        if (response.uid) {
          uid = response.uid;
        }
        const promises: Promise<FileResource | undefined | void>[] = [];
        promises.push(this.updateSessionByLocale({
          session: {
            uid,
            translations,
          },
        }));
        promises.push(this.updateSessionCategories({
          session: {
            uid,
            oldCategories: session.uid ? session.oldCategories : [],
            newCategories: session.newCategories,
          },
        }));
        if (this.$data.newDisplayFileResource
          && this.$data.newDisplayFileResource.readerResult
          && this.$data.newDisplayFileResource.fileName) {
          UploadFileHandler.uploadFileResourceHandler({
            base64Path: this.$data.newDisplayFileResource.readerResult,
            fileName: this.$data.newDisplayFileResource.fileName,
            schemaCode: this.community?.code as string,
          }).then((request) => {
            request.onload = () => {
              if (request?.status !== 413) {
                const uploadToken = JSON.parse(request.response)?.uploadToken;
                if (uploadToken) {
                  if (this.$data.oldDisplayFileResource && this.$data.oldDisplayFileResource.uid) {
                    promises.push(this.deleteFileResource(this.$data.oldDisplayFileResource.uid));
                  }
                  promises.push(this.createBannerFileResourceForSession({
                    uid,
                    uploadToken,
                    fileName: this.$data.newDisplayFileResource.fileName,
                    fileType: this.$data.newDisplayFileResource.fileType,
                  }));
                  Promise.all(promises)
                    .then(() => {
                      if (this.session) {
                        this.showToast(ToastActionType.UPDATE_SESSION);
                      } else {
                        this.showToast(ToastActionType.CREATE_SESSION);
                      }
                    }).then(() => {
                      this.saveLoading = false;
                      this.onSaveEnd();
                    });
                }
                return Promise.resolve(undefined);
              }
              return Promise.resolve(undefined);
            };
          });
        } else if (this.$data.newDisplayFileResource
          && !this.$data.newDisplayFileResource.readerResult
          && !this.$data.newDisplayFileResource.fileName
          && this.$data.oldDisplayFileResource
          && this.$data.oldDisplayFileResource.uid) {
          promises.push(this.deleteFileResource(this.$data.oldDisplayFileResource.uid));
          Promise.all(promises)
            .then(() => {
              if (this.session) {
                this.showToast(ToastActionType.UPDATE_SESSION);
              } else {
                this.showToast(ToastActionType.CREATE_SESSION);
              }
            }).then(() => {
              this.saveLoading = false;
              this.onSaveEnd();
            });
        } else {
          Promise.all(promises)
            .then(() => {
              if (this.session) {
                this.showToast(ToastActionType.UPDATE_SESSION);
              } else {
                this.showToast(ToastActionType.CREATE_SESSION);
              }
            }).then(() => {
              this.saveLoading = false;
              this.onSaveEnd();
            });
        }
      }
    });
  }

  private onSaveEnd(): void {
    this.$emit('on-update-end');
    this.$bvModal.hide(this.modalId);
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
  }

  private onDelete(): void {
    this.$emit('on-delete', {
      sessionId: this.session.uid,
    });
  }

  private onShow(): void {
    this.updateSession();
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }

  private onDescriptionChange(data: { html: string; text: string; locale?: string}): void {
    this.updateTranslations('description', {
      field: 'description',
      locale: data.locale,
      value: data.html,
    } as EntityTranslationParams);
  }
}
