
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class BaseCardWidget extends Vue {
  @Prop({
    required: false,
    default: false,
  })
  protected readonly urlOpenNewWindow!: boolean;

  protected onCardClick(route: string | null): void {
    if (route && this.urlOpenNewWindow) {
      window.open(route, '_blank');
    }
  }
}
