import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableStore from '@/store/LoadableStore';
import LoadableState from '@/store/states/LoadableState';
import Poll from '@/models/graphql/Poll';
import PollRepository from '@/repositories/PollRepository';
import { BasicTypes } from '@/utils/types/BasicTypes';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { format, getUnixTime, subSeconds } from 'date-fns';
import PollAnswer from '@/models/graphql/PollAnswer';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import PollUserAnswer from '@/models/graphql/PollUserAnswer';
import PollUserAnswerVersion from '@/models/graphql/PollUserAnswerVersion';

interface PollState extends LoadableState {
  statePolls: Poll[];
  statePoll: Poll;
  page: number;
  itemPerPage: number;
}

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class PollDynamicBaseStore extends LoadableStore<PollState> {
  private statePolls: Poll[] = [];

  private statePoll: Poll | null = null;

  private page = 0;

  private itemsPerPage = 10;

  private readonly pollRepository = new PollRepository();

  get poll(): Poll | null {
    return this.statePoll;
  }

  get polls(): Poll[] {
    return this.statePolls;
  }

  @Action
  loadPolls(payload: Record<string, BasicTypes>): Promise<number | null> {
    this.context.commit('load', true);
    const { usePagination } = payload;
    return this.pollRepository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter as object | null | undefined,
          type: GqlEntityFilterType.POLL_FILTER,
        },
        orderBy: {
          value: ['endTimestamp_desc', 'id_desc'],
          type: GqlEntityOrderingType.POLL_ORDERING,
        },
        ...(usePagination ? {
          first: this.itemsPerPage,
          offset: this.itemsPerPage * this.page,
        } : {}),
      }),
      operationName: payload.operationName as string | undefined,
      authUser: payload.authUser as string,
    })
      .then((response) => {
        if (this.page === 0) {
          if (response.length !== 0) {
            this.context.commit('setPage', 1);
          }
        } else {
          this.context.commit('setPage', this.page + 1);
        }
        this.context.commit('addElements', response);
        this.context.commit('load', false);
        return response.length;
      })
      .catch(() => new Promise((resolve) => resolve(null)));
  }

  @Action
  update(payload: Partial<Poll>): Promise<Poll | undefined> {
    return this.pollRepository.update({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.POLL_INPUT,
          value: payload,
        },
      ]),
    });
  }

  @Action
  reset(poll: Partial<Poll>): Promise<Poll | undefined> {
    return this.pollRepository.reset({
      definition: buildMutationDefinition([
        {
          fieldName: 'pollUid',
          type: GqlEntityInputType.REQUIRED_ID,
          value: poll.uid,
        },
      ]),
    })
      .catch(() => Promise.resolve(undefined));
  }

  @Action
  delete(uid: string): Promise<Poll | undefined> {
    return this.pollRepository.delete({
      definition: buildMutationDefinition([{
        fieldName: 'uid',
        type: 'ID',
        value: uid,
      }]),
    });
  }

  @Action
  publish(payload: {
    uid: string;
    startTime: string;
    endTime: string;
    allowNewVersion: boolean;
    realTimeAnswers: boolean;
  }): Promise<Poll | undefined> {
    return this.pollRepository.publish({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.REQUIRED_ID,
          value: payload.uid,
        },
        {
          fieldName: 'startTime',
          type: 'DateTime!',
          value: payload.startTime,
        },
        {
          fieldName: 'endTime',
          type: 'DateTime',
          value: payload.endTime,
        },
        {
          fieldName: 'allowNewVersion',
          type: 'Boolean',
          value: payload.allowNewVersion,
        },
        {
          fieldName: 'realTimeAnswers',
          type: 'Boolean',
          value: payload.realTimeAnswers,
        },
      ]),
      fragmentName: 'pollFullFragment',
    });
  }

  @Action
  resetStates(): void {
    this.context.commit('setPage', 0);
    this.context.commit('setElement', null);
    this.context.commit('setElements', []);
  }

  @Mutation
  setElements(list: Poll[]): void {
    this.statePolls = list;
  }

  @Mutation
  editPoll(poll: Poll): void {
    const localPollIndex = this.statePolls.findIndex((p) => p.uid === poll.uid);
    if (localPollIndex >= 0) {
      this.statePolls.splice(localPollIndex,
        1,
        {
          ...this.statePolls[localPollIndex],
          ...poll,
        });
    }
  }

  @Mutation
  removePoll(pollUid: string): void {
    const localPollIndex = this.statePolls.findIndex((p) => p.uid === pollUid);
    if (localPollIndex >= 0) {
      this.statePolls.splice(localPollIndex, 1);
    }
  }

  @Mutation
  closePoll({
    pollUid,
    bufferTime,
  }: { pollUid: string; bufferTime: number }): void {
    const localPollIndex = this.statePolls.findIndex((p) => p.uid === pollUid);
    if (localPollIndex >= 0) {
      const endTime = subSeconds(
        DateTimeHelper.toUTC(
          DateTimeHelper.getCurrentDateTime(),
        ),
        bufferTime,
      );
      this.statePolls[localPollIndex].endTime = format(endTime, DateTimeHelper.TIME_FORMAT_ISO_8601);
      this.statePolls[localPollIndex].endTimestamp = getUnixTime(endTime);
    }
  }

  @Mutation
  resetPoll(pollUid: string): void {
    const localPollIndex = this.statePolls.findIndex((p) => p.uid === pollUid);
    if (localPollIndex >= 0) {
      this.statePolls[localPollIndex].startTime = undefined;
      this.statePolls[localPollIndex].startTimestamp = undefined;
      this.statePolls[localPollIndex].endTime = undefined;
      this.statePolls[localPollIndex].endTimestamp = undefined;
      this.statePolls[localPollIndex].answerCountByAnswerId = [];
      const answers = this.statePolls[localPollIndex]?.pollAnswers || [];
      this.statePolls[localPollIndex].pollAnswers = answers?.map((pollAnswer) => ({
        ...pollAnswer,
        answerCount: 0,
        pollUserAnswers: [],
      }));
    }
  }

  @Mutation
  setVersion(payload: { uid: string; versions: PollUserAnswerVersion[] }): void {
    const localPollIndex = this.statePolls.findIndex((p) => p.uid === payload.uid);
    if (localPollIndex >= 0) {
      this.statePolls[localPollIndex].versions = payload.versions;
    }
  }

  @Mutation
  setMyAnswer({
    pollUid,
    myAnswer,
    myOldAnswer,
  }: {
    pollUid: string;
    myAnswer: PollUserAnswer;
    myOldAnswer: PollAnswer | null;
  }): void {
    const localPollIndex = this.statePolls.findIndex((p) => p.uid === pollUid);
    if (localPollIndex >= 0) {
      const localPoll = this.statePolls[localPollIndex];
      // eslint-disable-next-line no-underscore-dangle
      localPoll._myAnswer = [myAnswer];
      if (myOldAnswer) {
        if (localPoll.pollAnswers) {
          const oldLocalPollAnswer = localPoll.pollAnswers.find((pa) => pa.uid === myOldAnswer.uid);
          if (oldLocalPollAnswer && oldLocalPollAnswer.answerCount && oldLocalPollAnswer.answerCount > 0) {
            oldLocalPollAnswer.answerCount -= 1;
          }
        }
        if (localPoll.answerCountByAnswerId) {
          const oldLocalAnswerCountByAnswerIndex = localPoll.answerCountByAnswerId
            .findIndex((acbId) => acbId.key === `${myOldAnswer.id}`);
          if (oldLocalAnswerCountByAnswerIndex > -1) {
            const value = parseInt(localPoll.answerCountByAnswerId[oldLocalAnswerCountByAnswerIndex].value || '0', 10);
            if (value > 1) {
              localPoll.answerCountByAnswerId[oldLocalAnswerCountByAnswerIndex].value = `${value - 1}`;
            } else {
              localPoll.answerCountByAnswerId.splice(oldLocalAnswerCountByAnswerIndex, 1);
            }
          }
        }
      }
      if (localPoll.pollAnswers) {
        const newLocalPollAnswer = localPoll.pollAnswers.find((pa) => pa.uid === myAnswer.pollAnswer?.uid);
        if (newLocalPollAnswer) {
          if (newLocalPollAnswer.answerCount) {
            newLocalPollAnswer.answerCount += 1;
          } else {
            newLocalPollAnswer.answerCount = 1;
          }
        }
      }
      if (!localPoll.answerCountByAnswerId) {
        localPoll.answerCountByAnswerId = [];
      }
      const localAnswerCountByAnswerIndex = localPoll.answerCountByAnswerId
        .findIndex((acbId) => acbId.key === `${myAnswer.pollAnswer?.id}`);
      if (localAnswerCountByAnswerIndex > -1) {
        const value = parseInt(localPoll.answerCountByAnswerId[localAnswerCountByAnswerIndex].value || '0', 10);
        localPoll.answerCountByAnswerId[localAnswerCountByAnswerIndex].value = `${value + 1}`;
      } else {
        localPoll.answerCountByAnswerId.push({
          key: `${myAnswer.pollAnswer?.id}`,
          value: '1',
        });
      }
    }
  }

  @Mutation
  answerUpdate({
    pollUid,
    pollAnswer,
    oldPollAnswer,
  }: { pollUid: string; pollAnswer: PollAnswer; oldPollAnswer: PollAnswer | null }): void {
    const localPollIndex = this.statePolls.findIndex((p) => p.uid === pollUid);
    if (localPollIndex >= 0) {
      const localPoll = this.statePolls[localPollIndex];
      if (oldPollAnswer) {
        if (localPoll.pollAnswers) {
          const oldLocalPollAnswer = localPoll.pollAnswers.find((pa) => pa.uid === oldPollAnswer.uid);
          if (oldLocalPollAnswer && oldLocalPollAnswer.answerCount && oldLocalPollAnswer.answerCount > 0) {
            oldLocalPollAnswer.answerCount -= 1;
          }
        }
        if (localPoll.answerCountByAnswerId) {
          const oldLocalAnswerCountByAnswerIndex = localPoll.answerCountByAnswerId
            .findIndex((acbId) => acbId.key === `${oldPollAnswer.id}`);
          if (oldLocalAnswerCountByAnswerIndex > -1) {
            const value = parseInt(localPoll.answerCountByAnswerId[oldLocalAnswerCountByAnswerIndex].value || '0', 10);
            if (value > 1) {
              localPoll.answerCountByAnswerId[oldLocalAnswerCountByAnswerIndex].value = `${value - 1}`;
            } else {
              localPoll.answerCountByAnswerId.splice(oldLocalAnswerCountByAnswerIndex, 1);
            }
          }
        }
      }
      const { pollAnswers } = this.statePolls[localPollIndex];
      const localPollAnswerIndex = pollAnswers ? pollAnswers.findIndex((p) => p.uid === pollAnswer.uid) : -1;

      if (localPollAnswerIndex >= 0) {
        const localPollAnswer = pollAnswers && pollAnswers.length ? pollAnswers[localPollAnswerIndex] : null;
        if (localPollAnswer) {
          localPollAnswer.answerCount = localPollAnswer.answerCount
            ? localPollAnswer.answerCount + 1
            : 1;
          if (localPoll.answerCountByAnswerId && localPoll.answerCountByAnswerId.length) {
            const answerCountsIndex = localPoll.answerCountByAnswerId
              ? localPoll.answerCountByAnswerId
                .findIndex((p) => p.key === (pollAnswer && pollAnswer.id ? pollAnswer.id.toString() : ''))
              : -1;
            if (answerCountsIndex >= 0) {
              const { answerCountByAnswerId } = this.statePolls[localPollIndex];
              if (answerCountByAnswerId && answerCountByAnswerId.length) {
                const { value } = answerCountByAnswerId[answerCountsIndex];
                answerCountByAnswerId[answerCountsIndex].value = (parseInt(value || '0', 10) + 1).toString();
              }
            } else if (pollAnswer && pollAnswer.id) {
              localPoll.answerCountByAnswerId.push({
                key: pollAnswer.id.toString(),
                value: '1',
              });
            }
          } else if (pollAnswer && pollAnswer.id) {
            localPoll.answerCountByAnswerId = [{
              key: pollAnswer.id.toString(),
              value: '1',
            }];
          }
        }
      }
    }
  }

  @Mutation
  setElement(poll: Poll): void {
    this.statePoll = poll;
  }

  @Mutation
  addElements(polls: Poll[]): void {
    this.statePolls = [...this.statePolls];
    polls.forEach((newPoll) => {
      const pollIndex = this.statePolls.findIndex((p) => p.uid === newPoll.uid);
      if (pollIndex > -1) {
        this.statePolls.splice(pollIndex, 1, newPoll);
      } else {
        this.statePolls.push(newPoll);
      }
    });
  }

  @Mutation
  setPage(page: number): void {
    this.page = page;
  }
}
