import { render, staticRenderFns } from "./LivePollComponent.vue?vue&type=template&id=3b429be4&scoped=true&"
import script from "./LivePollComponent.vue?vue&type=script&lang=ts&"
export * from "./LivePollComponent.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/live-session/LivePollComponent.scss?vue&type=style&index=0&id=3b429be4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b429be4",
  null
  
)

export default component.exports