




























































































































































































































































































































/* eslint-disable no-underscore-dangle */
import { Component, Prop, Watch } from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import InputText from '@/components/InputText.vue';
import { namespace } from 'vuex-class';
import FeedItemLinkForm from '@/components/feed/FeedItemLinkForm.vue';
import FeedPost from '@/models/graphql/FeedPost';
import Exhibitor from '@/models/graphql/Exhibitor';
import SubEdition from '@/models/graphql/SubEdition';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import UploadAssetComponent from '@/components/UploadAssetComponent.vue';
import FileType from '@/utils/enums/FileType';
import FeedPostType from '@/utils/enums/feed/FeedPostType';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import FeedPostMedia from '@/models/graphql/FeedPostMedia';
import FeedItemWrapper from '@/models/graphql/FeedItemWrapper';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import FeedPostLink from '@/models/graphql/FeedPostLink';
import Channel from '@/models/graphql/Channel';
import FeedType from '@/utils/enums/feed/FeedType';
import PollCreationFormComponent from '@/components/PollCreationFormComponent.vue';
import ChoiceParams from '@/utils/types/ChoiceParams';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  addDays, addHours, addMinutes, format,
} from 'date-fns';
import DropdownComponent from '@/components/form/DropdownComponent.vue';
import Poll from '@/models/graphql/Poll';
import PollAnswer from '@/models/graphql/PollAnswer';
import ActionType from '@/utils/enums/ActionType';

const feedItemWrapperStore = namespace('FeedItemWrapperStore');
const pollStore = namespace('PollStore');

@Component({
  data(): object {
    return {
      medias: [],
      images: [],
      videos: [],
      content: '',
      links: [],
      poll: null,
    };
  },
  components: {
    ButtonIconComponent,
    FeedItemLinkForm,
    StandardModal,
    AvatarSoloWidget,
    InputText,
    ButtonComponent,
    UploadAssetComponent,
    FontAwesomeComponent,
    ConfirmModal,
    PollCreationFormComponent,
    DropdownComponent,
  },
})
export default class FeedItemCreatorModal extends VueBaseWidget {
  @Prop({ default: '' })
  private modalId!: string;

  @Prop({
    required: false,
    default: null,
  })
  private container: string | null = null;

  @Prop({ required: false })
  private feedItemWrapper!: FeedItemWrapper;

  @Prop({
    required: false,
    default: FeedPostType.TEXT,
  })
  private openWith!: FeedPostType;

  @Prop({
    required: false,
    default: null,
  })
  private type!: string;

  @feedItemWrapperStore.Action
  private createPost!: (payload: {
    initiator: CommunityUser | Exhibitor | SubEdition | Channel;
    authUser: CommunityUser;
    entity: Partial<FeedPost>;
    links: Partial<FeedPostLink>[];
    medias: Partial<FeedPostMedia>[];
    topicUid: string | null;
    channelUid: string | null;
  }) => Promise<{ wrapperUid: string; postUid: string } | null>;

  @feedItemWrapperStore.Action
  private updatePost!: (payload: {
    feedItemWrapper: FeedItemWrapper;
    entity: Partial<FeedPost>;
    oldEntity: FeedPost;
    links: Partial<FeedPostLink>[];
    medias: Partial<FeedPostMedia>[];
  }) => Promise<void>;

  @feedItemWrapperStore.Action
  private attachMediaToPost!: (payload: {
    feedPostUid: string;
    media: FeedPostLink;
  }) => Promise<void>;

  @feedItemWrapperStore.Action
  private attachLinkToPost!: (payload: {
    feedPostUid: string;
    link: FeedPostLink;
  }) => void;

  @feedItemWrapperStore.Action
  private deleteLinkPost!: (uid: string) => void;

  @feedItemWrapperStore.Getter
  private fetchFeedInitiator!: CommunityUser | Exhibitor | SubEdition | Channel;

  @pollStore.Action(ActionType.CREATE)
  private createFullPollForPost!: (payload: {
    postUid: string | null;
    sessionUid: string | null;
    entity: Poll;
    answers: PollAnswer[];
  }) => Promise<Poll | undefined>;

  @pollStore.Action(ActionType.UPDATE)
  private pollUpdate!: (payload: Partial<Poll>)
    => Promise<Poll | undefined>;

  private FeedPostType = FeedPostType;

  private oldFeedPost: FeedPost | null = null;

  private form = {
    title: '',
    choice1: '',
    choice2: '',
    choice3: '',
    choice4: '',
    choice5: '',
  };

  private selectedDay = this.days[1];

  private selectedHour = this.hours[0];

  private selectedMinute = this.minutes[0];

  private pollErrors = false;

  private saveWithTimeLoading = false;

  private saveWithoutTimeLoading = false;

  private showLinkCard = false;

  private FileType = FileType;

  private isNotLinkTyping = true;

  private loading = false;

  private uploadError = false;

  private content = '';

  private isEditMode = false;

  private mediaUploaded = false;

  private isNotSaving = true;

  private mediaShown: FeedPostType = FeedPostType.TEXT;

  private modalRenderKey = 0;

  private get initiator(): CommunityUser | Exhibitor | SubEdition | Channel | undefined {
    if (this.feedItemWrapper) {
      return this.feedItemWrapper.initiator;
    }
    if (this.fetchFeedInitiator) {
      return this.fetchFeedInitiator;
    }
    return undefined;
  }

  private get initiatorImage(): string {
    if (this.initiator) {
      if (this.authUser && this.initiator.uid === this.authUser.uid) {
        return CommunityUser.hydrate(
          this.initiator as CommunityUser,
        ).profilePicture;
      }
      return FileResourceHelper.getFullPath(
        (this.initiator as Exhibitor | SubEdition).logoFileResource,
        'w40',
      );
    }
    return '';
  }

  private get initiatorName(): string {
    if (this.initiator) {
      if (this.authUser && this.initiator.uid === this.authUser.uid) {
        return CommunityUser.hydrate(
          this.initiator as CommunityUser,
        ).fullName;
      }
      return (this.initiator as Exhibitor | SubEdition).name || '';
    }
    return '';
  }

  private get initiatorFirstName(): string {
    if (this.initiator) {
      if (this.authUser && this.initiator.uid === this.authUser.uid) {
        return CommunityUser.hydrate(
          this.initiator as CommunityUser,
        ).firstName as string;
      }
    }
    return '';
  }

  private get initiatorlastName(): string {
    if (this.initiator) {
      if (this.authUser && this.initiator.uid === this.authUser.uid) {
        return CommunityUser.hydrate(
          this.initiator as CommunityUser,
        ).lastName as string;
      }
    }
    return '';
  }

  private get isLoading(): boolean {
    return this.loading;
  }

  private get finalFeedPostType(): FeedPostType {
    if (
      (!this.$data.links.length && !this.$data.medias.length)
      || (this.mediaShown === FeedPostType.LINK && !this.$data.links.length)
      || ((this.mediaShown === FeedPostType.IMAGE || this.mediaShown === FeedPostType.VIDEO)
        && !this.$data.medias.length)
      || !this.mediaShown
    ) {
      return FeedPostType.TEXT;
    }
    return this.mediaShown;
  }

  private get validatedMedias(): FeedPostMedia[] {
    return this.$data.medias.filter((media: FeedPostMedia) => media.type !== undefined);
  }

  private get days(): ChoiceParams[] {
    const result = [];
    let count = 0;
    while (count <= 7) {
      result.push({
        text: this.$tc('session-polls.modal.poll-duration.dropdown.days',
          0,
          { days: count.toString() }),
        value: count.toString(),
      } as ChoiceParams);
      count += 1;
    }
    return result;
  }

  private get hours(): ChoiceParams[] {
    const result = [];
    let count = 0;
    while (count <= 23) {
      result.push({
        text: this.$tc('session-polls.modal.poll-duration.dropdown.hours',
          0,
          { hours: count.toString() }),
        value: count.toString(),
      } as ChoiceParams);
      count += 1;
    }
    return result;
  }

  private get minutes(): ChoiceParams[] {
    const result = [];
    let count = 0;
    if (this.selectedHour.value === '0' && this.selectedDay.value === '0') count = 5;
    while (count <= 59) {
      result.push({
        text: this.$tc('session-polls.modal.poll-duration.dropdown.minutes',
          0,
          { minutes: count.toString() }),
        value: count.toString(),
      } as ChoiceParams);
      count += 1;
    }
    return result;
  }

  created(): void {
    this.updateMessageTextArea();
  }

  // eslint-disable-next-line class-methods-use-this
  private resizeTextarea(): void {
    const textarea = document.getElementById('textArea-resize') as HTMLInputElement;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }

  @Watch('content')
  private updateMessageTextArea(): void {
    this.$nextTick(() => {
      if (this.authUser) {
        this.addAutoResize();
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private addAutoResize(): void {
    document.querySelectorAll<HTMLElement>('[data-autoresize]')
      .forEach((element) => {
        element.style.boxSizing = 'border-box';
        element.addEventListener('input', (event) => {
          if (event && event.target) {
            (event.target as HTMLElement).style.height = 'auto';
            (event.target as HTMLElement).style.height = `${(event.target as HTMLElement).scrollHeight}px`;
          }
        });
        element.removeAttribute('data-autoresize');
      });
  }

  private clear(): void {
    this.content = '';
    this.mediaShown = FeedPostType.EMPTY;
    this.openWith = FeedPostType.TEXT;
    this.showLinkCard = false;
    this.$data.links = [];
    this.$data.medias = [];
    this.oldFeedPost = null;
    Object.keys(this.$refs)
      .forEach((key) => {
        if (this.$refs[key] !== undefined && (this.$refs[key] as UploadAssetComponent[]).length) {
          if (key !== 'video') {
            (this.$refs[key] as UploadAssetComponent[])[0].clear();
          } else {
            (this.$refs[key] as UploadAssetComponent).clear();
          }
        }
      });
  }

  private isEmpty(): boolean {
    return Object.keys(this.$refs)
      .filter((key) => {
        if (this.$refs[key] !== undefined && (this.$refs[key] as UploadAssetComponent[]).length) {
          if (key !== 'video') {
            return (this.$refs[key] as UploadAssetComponent[])[0].hasFile();
          }
          return (this.$refs[key] as UploadAssetComponent).hasFile();
        }
        return false;
      }).length === 0;
  }

  @Watch('openWith', { deep: true })
  private setMediaShown(): void {
    if (this.openWith) {
      this.mediaShown = this.openWith;
    }
  }

  private toggleMedia(media: FeedPostType): void {
    if (this.mediaShown === media) {
      this.mediaShown = FeedPostType.TEXT;
    } else {
      this.mediaShown = media;
      this.openWith = media;
    }
    if (!this.isEditMode) {
      this.$data.medias = [];
      this.$data.links = [];
      this.$data.poll = null;
      this.mediaUploaded = false;
    }
  }

  private onShowModal(): void {
    if (
      this.feedItemWrapper && this.feedItemWrapper._feedItems
      && this.feedItemWrapper._feedItems.length !== 0
      && this.feedItemWrapper._feedItems[0].action === 'FEEDPOST_CREATED'
    ) {
      this.isEditMode = true;
      // eslint-disable-next-line max-len
      this.oldFeedPost = { ...this.feedItemWrapper._feedItems[0].triggered } as FeedPost;
      this.content = JSON.parse(JSON.stringify(this.oldFeedPost.content)) || '';
      this.$data.links = JSON.parse(JSON.stringify(this.oldFeedPost.links)) || [];
      this.$data.medias = JSON.parse(JSON.stringify(this.oldFeedPost.medias)) || [];
      this.$data.videos = this.$data.medias.filter((media: FeedPostMedia) => media.type === 'video');
      this.$data.images = this.$data.medias.filter((media: FeedPostMedia) => media.type === 'image');
      if (this.oldFeedPost.type === FeedPostType.EMPTY) {
        if (this.$data.links.length) {
          this.mediaShown = FeedPostType.LINK;
          this.showLinkCard = true;
        }
        if (this.$data.videos.length) {
          this.mediaShown = FeedPostType.VIDEO;
        }
        if (this.$data.images.length) {
          this.mediaShown = FeedPostType.IMAGE;
        }
      } else {
        this.mediaShown = this.oldFeedPost.type as FeedPostType;
        if (this.mediaShown === FeedPostType.LINK && this.$data.links.length > 0) {
          this.showLinkCard = true;
        }
      }
    }
    this.modalRenderKey += 1;
  }

  private onNewUrl(
    newlink: FeedPostLink | null,
  ): void {
    if (newlink !== null) {
      this.$data.links = [newlink];
      this.showLinkCard = true;
      this.mediaUploaded = true;
    } else {
      this.$data.links = [];
      this.showLinkCard = false;
      this.mediaUploaded = false;
    }
  }

  private onTyping(isTyping: boolean): void {
    this.isNotLinkTyping = isTyping;
  }

  private deleteLink(): void {
    this.showLinkCard = false;
    this.$data.links = [];
    this.mediaUploaded = false;
  }

  private create(): void {
    if (!this.isEditMode) {
      this.loading = true;
      this.isNotSaving = false;
      if (this.initiator) {
        this.initiator.__typename = FeedType.PROFILE;
        this.createPost({
          initiator: this.initiator,
          authUser: this.authUser,
          entity: {
            type: this.finalFeedPostType,
            content: this.content.trim(),
          },
          links: this.$data.links,
          medias: this.validatedMedias,
          topicUid: (this.type === FeedType.TOPIC) ? this.container : null,
          channelUid: (this.type === FeedType.CHANNEL) ? this.container : null,
        })
          .then((payload) => {
            this.loading = false;
            if (this.openWith === FeedPostType.POLL) {
              this.createPoll(payload?.postUid as string);
            } else {
              this.clear();
              this.content = '';
              this.isNotSaving = true;
              this.$bvModal.hide(this.modalId);
              this.$eventsBus.emit('feed-post-created', payload?.wrapperUid);
            }
          });
      }
    } else {
      this.loading = true;
      if (this.feedItemWrapper && this.feedItemWrapper._feedItems?.length) {
        this.updatePost({
          feedItemWrapper: this.feedItemWrapper,
          entity: {
            uid: this.feedItemWrapper._feedItems[0].triggered?.uid,
            content: this.content.trim(),
            type: this.finalFeedPostType,
          },
          oldEntity: this.oldFeedPost as FeedPost,
          medias: this.validatedMedias,
          links: this.$data.links,
        })
          .then(() => {
            this.loading = false;
            this.content = '';
            this.isNotSaving = true;
            this.$bvModal.hide(this.modalId);
            this.$eventsBus.emit('feed-post-created', this.feedItemWrapper.uid);
          });
      } else {
        this.isNotSaving = true;
        this.loading = false;
      }
    }
  }

  private createPoll(postUid: string): void {
    const poll = {
      title: this.form.title,
    } as Poll;
    this.saveWithTimeLoading = true;
    const startTime = DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime());
    const endTime = this.getPollEndTime(startTime);
    poll.startTime = format(startTime, DateTimeHelper.TIME_FORMAT_ISO_8601);
    poll.endTime = endTime ? format(endTime, DateTimeHelper.TIME_FORMAT_ISO_8601) : undefined;
    const pollAnswers: PollAnswer[] = [];
    Object.keys(this.form)
      .forEach((key: string, index: number) => {
        const { form } = this;
        const value = this.form[key as keyof typeof form];
        if (value.length && key !== 'title') {
          pollAnswers.push({
            title: value,
            displaySequence: index,
            correctAnswer: false,
          } as PollAnswer);
        }
      });
    this.createFullPollForPost({
      postUid,
      entity: poll,
      answers: pollAnswers,
      sessionUid: null,
    })
      .then(() => {
        this.saveWithTimeLoading = false;
        this.saveWithoutTimeLoading = false;
        this.loading = false;
        this.content = '';
        this.isNotSaving = true;
        this.onPollCreateRefresh();
        this.$bvModal.hide(this.modalId);
      });
  }

  private onPollCreateRefresh(): void {
    this.$eventsBus.emit('on-poll-create-refresh');
  }

  private onMediaFileUpload(
    event: { readerResult: string; fileName: string; fileType: string } | null,
    index: number,
    type: string,
  ): void {
    if (event && event.readerResult) {
      const base64Path = event?.readerResult.replace(`data:${event.fileType};base64,`, '');
      this.$data.medias[index] = {
        type,
        mediaFileResourceBase64: base64Path,
        mediaFileResourceFilename: event.fileName,
        sequence: index,
      };
      this.uploadError = false;
      this.mediaUploaded = true;
    } else if (event && event.readerResult === null) {
      this.$data.medias[index] = {};
      this.uploadError = false;
      this.mediaUploaded = !this.isEmpty();
    } else {
      this.uploadError = true;
    }
  }

  private onDiscard(): void {
    if (this.isNotSaving) {
      this.$bvModal.hide(this.modalId);
    }
    if (!this.isEditMode
      && (this.content
        || this.$data.medias.find((media: FeedPostMedia) => Object.keys(media).length !== 0)
        || this.$data.links.length > 0)
    ) {
      this.$bvModal.show(`warning-discard-${this.modalId}`);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private onPoll(payload: {
    form: {
      title: string;
      choice1: string;
      choice2: string;
      choice3: string;
      choice4: string;
      choice5: string;
    }; error: boolean;
  }): void {
    this.form = payload.form;
    this.pollErrors = payload.error;
  }

  private getPollEndTime(startTime: Date): Date {
    return addDays(
      addHours(
        addMinutes(
          startTime,
          parseInt(this.selectedMinute.value, 10),
        ),
        parseInt(this.selectedHour.value, 10),
      ),
      parseInt(this.selectedDay.value, 10),
    );
  }

  private onDayChange(day: ChoiceParams): void {
    this.selectedDay = day;
    if (day && day.value === '7') {
      // eslint-disable-next-line prefer-destructuring
      this.selectedHour = this.hours[0];
      // eslint-disable-next-line prefer-destructuring
      this.selectedMinute = this.minutes[0];
    }
    if (day && day.value === '0' && this.selectedHour.value === '0') {
      // eslint-disable-next-line prefer-destructuring
      this.selectedMinute = this.minutes[0];
    }
  }

  private onHourChange(hour: ChoiceParams): void {
    this.selectedHour = hour;
    if (hour && hour.value === '0' && this.selectedDay.value === '0') {
      // eslint-disable-next-line prefer-destructuring
      this.selectedMinute = this.minutes[0];
    }
  }
}
