var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'align-items-center crm-user-card-buttons',
    {'w-100': _vm.isMobile},
    {'is-desktop': _vm.isDesktop},
    {'justify-content-end': _vm.isMobile && _vm.cardContext === 'meetingRequest'}
  ]},[(_vm.cardContext === 'meetingRequest')?_c('div',{staticClass:"mr-2"},[(_vm.isDesktop || _vm.isMobile)?_c('button-component',{attrs:{"rounded":true,"size":_vm.isMobile ? 'sm' : 'md',"text":_vm.isScheduled
        ? ("" + (_vm.$t('component.crm-user-card.scheduled-btn')))
        : ("" + (_vm.$t('component.crm-user-card.schedule-btn'))),"variant":_vm.isScheduled ? 'alt-2' : 'secondary'},on:{"on-click":function($event){return _vm.$emit('on-schedule-click')}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":_vm.isMobile ? 'icon-16' : 'icon-24',"icon":_vm.isScheduled ? 'fa-regular fa-calendar-check' : 'fa-regular fa-calendar-plus',"icon-classes":_vm.isMobile ? 'icon-12' : ''}})]},proxy:true}],null,false,491402723)}):_vm._e(),(_vm.isTablet)?_c('button-icon-component',{staticStyle:{"width":"2.5rem","height":"2.5rem"},attrs:{"variant":_vm.isScheduled ? 'alt-2' : 'secondary',"shape":"round","size":"md"},on:{"on-click":function($event){return _vm.emit('on-schedule-click')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":_vm.isScheduled ? 'fa-regular fa-calendar-check' : 'fa-regular fa-calendar-plus',"container-classes":"icon-16"}})]},proxy:true}],null,false,1493080056)}):_vm._e()],1):_vm._e(),(_vm.ourConnection === null
      && _vm.isProfileVisible
      && _vm.ActionRuleService.isRuleValid(_vm.actions, _vm.ActionRule.CAN_CONNECT)
      && _vm.cardContext === 'user')?[_c('div',{class:[
        {'w-100': _vm.isMobile}
      ]},[_c('button-component',{attrs:{"rounded":true,"text":("" + (_vm.$t('actions.add-connection'))),"size":"md","variant":"secondary"},on:{"on-click":function($event){return _vm.$emit('toggle-modal')}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{staticClass:"mr-2",attrs:{"container-classes":"icon-24","icon":"fa-regular fa-user-plus"}})]},proxy:true}],null,false,1470354321)})],1)]:(_vm.ourConnection
      && _vm.ourConnection.linkState === _vm.connectionStatusType.INVITED
      && _vm.ActionRuleService.isRuleValid(_vm.actions, _vm.ActionRule.CAN_CONNECT)
      && _vm.cardContext === 'user')?_c('div',{class:[
      {'w-100': _vm.isMobile}
    ]},[(_vm.ourConnection.connectedUser
        && _vm.ourConnection.connectedUser.uid === _vm.authUser.uid)?_c('div',{staticClass:"button-container-lg"},[_c('div',[_c('button-component',{attrs:{"rounded":true,"text":("" + (_vm.$t('component.crm-user-card.accept-btn'))),"size":"md","variant":"secondary"},on:{"on-click":function($event){return _vm.$emit('on-accept-connection')}}})],1),_c('div',[_c('button-component',{attrs:{"rounded":true,"text":("" + (_vm.$t('component.crm-user-card.decline-btn'))),"size":"md","variant":"secondary-red"},on:{"on-click":function($event){return _vm.$emit('on-decline-connection')}}})],1)]):(_vm.cardContext === 'user')?_c('div',{class:[
        {'button-container-lg': _vm.isDesktop},
        {'w-100': _vm.isMobile}
      ]},[_c('button-component',_vm._b({attrs:{"rounded":true,"text":("" + (_vm.$t('actions.awaiting-invited-contact'))),"size":"md","variant":"secondary card-height card-style-disabled"},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{staticClass:"mr-2",attrs:{"container-classes":"icon-24","icon":"fa-regular fa-user-check","icon-classes":"text-neutral-n-5-placeholder"}})]},proxy:true}])},'button-component',_vm.useTestDataAttribute('pending-connection-button'),false))],1):_vm._e()]):(_vm.cardContext === 'user'
      && _vm.ourConnection
      && _vm.ourConnection.linkState === _vm.connectionStatusType.ACCEPTED
      && _vm.ActionRuleService.isRuleValid(_vm.actions, _vm.ActionRule.CAN_CONNECT))?_c('div',{class:[
      {'button-container-lg': _vm.isDesktop},
      {'w-100': _vm.isMobile && _vm.cardContext === 'user'},
      {'d-flex column-gap-2': !_vm.isMobile}
    ]},[(_vm.cardContext === 'user')?_c('button-component',{attrs:{"no-border":true,"rounded":true,"text":("" + (_vm.$t('actions.added-connection'))),"size":"md","variant":"alt-2"},on:{"on-click":function($event){return _vm.$emit('toggle-modal')}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{staticClass:"mr-2",attrs:{"container-classes":"icon-24","icon":"fa-regular fa-user-check"}})]},proxy:true}],null,false,3697997773)}):_vm._e(),(_vm.isFeatureActivated(_vm.FeatureKeys.COMMUNITY_CHAT_FEATURE)
        && _vm.cardContext === 'user'
        && !_vm.isMobile)?_c('button-icon-component',{attrs:{"rounded":true,"size":"md","variant":"tertiary"},on:{"on-click":function($event){return _vm.$emit('on-open-conversation')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":_vm.isMobile ? 'icon-16' : 'icon-24',"icon-classes":_vm.isMobile ? 'icon-12' : '',"icon":"fa-regular fa-comment-dots"}})]},proxy:true}],null,false,265884991)}):_vm._e(),(_vm.isFeatureActivated(_vm.FeatureKeys.COMMUNITY_AGENDA_FEATURE)
        && _vm.ActionRuleService.isRuleValid(_vm.actions, _vm.ActionRule.CAN_MEET)
        && _vm.cardContext === 'user'
        && !_vm.isMobile)?_c('button-icon-component',{attrs:{"rounded":true,"size":"md","variant":"tertiary"},on:{"on-click":function($event){return _vm.$emit('on-create-meeting')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":_vm.isMobile ? 'icon-16' : 'icon-24',"icon-classes":_vm.isMobile ? 'icon-12' : '',"icon":"fa-regular fa-calendar-plus"}})]},proxy:true}],null,false,47456552)}):_vm._e()],1):_vm._e(),_c('dropdown-menu',{class:[
      {'ml-2': _vm.cardContext === 'user'}
    ],attrs:{"dropdown-spacing":_vm.isMobile ? 'md' : 'lg',"size":_vm.isMobile && _vm.cardContext === 'meetingRequest' ? 'sm' : '40',"mobile-size":"sm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var close = ref.close;
return [_c('dropdown-menu-item',{attrs:{"text":("" + (_vm.$t('component.crm-user-card.menu-item.view-profile'))),"icon":"far fa-user-circle"},on:{"on-click-stop":function($event){_vm.$emit('on-view-profile'); close();}}}),(_vm.cardContext === 'meetingRequest'
          && _vm.ActionRuleService.isRuleValid(_vm.actions, _vm.ActionRule.CAN_CONNECT))?[(_vm.ourConnection
            && _vm.ourConnection.linkState === _vm.connectionStatusType.ACCEPTED)?_c('dropdown-menu-item',{attrs:{"text":("" + (_vm.$t('actions.added-connection'))),"icon":"far fa-user-check"},on:{"on-click-stop":function($event){_vm.$emit('toggle-modal'); close();}}}):_vm._e(),(_vm.ourConnection
            && _vm.ourConnection.linkState === _vm.connectionStatusType.INVITED)?_c('dropdown-menu-item',{attrs:{"disabled":true,"text":("" + (_vm.$t('actions.pending-connection'))),"icon":"far fa-user-check"},on:{"on-click-stop":function($event){return close();}}}):_vm._e(),(_vm.ourConnection === null)?_c('dropdown-menu-item',{attrs:{"text":("" + (_vm.$t('actions.add-connection'))),"icon":"far fa-user-plus"},on:{"on-click-stop":function($event){_vm.$emit('toggle-modal'); close();}}}):_vm._e()]:_vm._e(),((_vm.cardContext === 'meetingRequest'
          || (_vm.cardContext === 'user' && _vm.isMobile))
          && _vm.isFeatureActivated(_vm.FeatureKeys.COMMUNITY_CHAT_FEATURE)
          && _vm.ourConnection
          && _vm.ourConnection.linkState === _vm.connectionStatusType.ACCEPTED)?_c('dropdown-menu-item',{attrs:{"text":("" + (_vm.$t('component.crm-user-card.message-btn'))),"icon":"far fa-comment-dots"},on:{"on-click-stop":function($event){_vm.$emit('on-open-conversation'); close();}}}):_vm._e(),(_vm.isMobile
          && _vm.cardContext === 'user'
          && _vm.isFeatureActivated(_vm.FeatureKeys.COMMUNITY_AGENDA_FEATURE)
          && _vm.ActionRuleService.isRuleValid(_vm.actions, _vm.ActionRule.CAN_MEET)
          && _vm.ourConnection
          && _vm.ourConnection.linkState === _vm.connectionStatusType.ACCEPTED)?_c('dropdown-menu-item',{attrs:{"text":("" + (_vm.$t('component.crm-user-card.menu-item.request-meeting'))),"icon":"far fa-calendar-plus"},on:{"on-click-stop":function($event){_vm.$emit('on-create-meeting'); close();}}}):_vm._e(),(_vm.cardContext === 'meetingRequest' && !_vm.isArchived)?_c('dropdown-menu-item',{attrs:{"text":_vm.isArchived
          ? ("" + (_vm.$t('component.crm-user-card.archived-btn')))
          : ("" + (_vm.$t('component.crm-user-card.archive-btn'))),"icon":"far fa-folder-arrow-down"},on:{"on-click-stop":function($event){_vm.$emit('on-archive-click'); close();}}}):_vm._e(),_c('dropdown-menu-item',{attrs:{"text":_vm.text,"icon":"far fa-ban"},on:{"on-click-stop":function($event){_vm.toggleBlockModal(); close();}}})]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }