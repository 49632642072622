























































import { Component, Prop, Vue } from 'vue-property-decorator';
import MeetingRequest from '@/models/graphql/MeetingRequest';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import { format } from 'date-fns';
import { State } from 'vuex-class';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: {
    FontAwesomeComponent,
    ButtonComponent,
    AvatarSoloWidget,
  },
})
export default class EpMeetingRequestDashboardItemComponent extends Vue {
  @Prop({ required: true })
  private meetingRequest!: MeetingRequest;

  @Prop({ required: true })
  private selectedFilter!: string;

  @State
  private dateLocale!: Locale;

  private get user(): CommunityUser {
    return CommunityUser.hydrate(this.meetingRequest.user || {});
  }

  private get createdTime(): string {
    return format(
      this.meetingRequest.createdTimestamp * 1000,
      `${this.$t('app.date.monthDayShort')}`,
      { locale: this.dateLocale },
    );
  }

  private get subTitle(): string {
    const temp = [];
    if (this.user.jobTitle) {
      temp.push(this.user.jobTitle);
    }
    if (this.user.employerName) {
      temp.push(this.user.employerName);
    }
    return temp.join(` ${this.$tc('actions.at', 0, { employer: '' })} `)
      .trim();
  }

  private goToMeetingRequestDetail(): void {
    this.meetingRequest.readState = true;
    this.$store.dispatch(
      'MeetingRequestStore/updateMeetingRequest',
      {
        entity: {
          uid: this.meetingRequest.uid,
          readState: true,
        },
      },
    );
    this.$router.push({
      name: 'exhibitor-portal-meeting-requests',
      query: {
        tabView: this.selectedFilter,
        detail: this.meetingRequest.uid,
      },
    });
  }

  private goToUserDetail(): void {
    this.$router.push({
      name: 'exhibitor-portal-user',
      params: {
        memberId: this.user.uid,
      },
    });
  }
}
