import { Data } from '@/utils/types/WidgetData';
import EntityType from '@/utils/enums/EntityType';
import { getUnixTime } from 'date-fns';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import StatusOptions from '@/components/company-cms/StatusOptions';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import CompanyRoleEnum from '@/utils/enums/CompanyRoleEnum';

export default class HeaderMenuItems {
  static statusOptions = StatusOptions;

  static getHeaderMenuItems(list: Data[], entityType: string): object {
    return {
      product: {
        publish: {
          name: 'publish',
          translation: 'admin-panel.menus.item.publish',
          icon: 'fa-regular fa-cloud-arrow-up',
          canShow: this.canPublishAll(list, entityType),
        },
        unpublish: {
          name: 'unpublish',
          translation: 'admin-panel.menus.item.unpublish',
          icon: 'fa-regular fa-cloud-xmark',
          canShow: this.canUnpublishAll(list, entityType),
        },
        delete: {
          name: 'delete',
          translation: 'admin-panel.menus.item.delete',
          icon: 'fa-regular fa-trash-can',
          canShow: true,
        },
      },
      largeproduct: {
        publish: {
          name: 'publish',
          translation: 'admin-panel.menus.item.publish',
          icon: 'fa-regular fa-cloud-arrow-up',
          canShow: this.canPublishAll(list, entityType),
        },
        unpublish: {
          name: 'unpublish',
          translation: 'admin-panel.menus.item.unpublish',
          icon: 'fa-regular fa-cloud-xmark',
          canShow: this.canUnpublishAll(list, entityType),
        },
        delete: {
          name: 'delete',
          translation: 'admin-panel.menus.item.delete',
          icon: 'fa-regular fa-trash-can',
          canShow: true,
        },
      },
      boatproduct: {
        delete: {
          name: 'delete',
          translation: 'admin-panel.menus.item.delete',
          icon: 'fa-regular fa-trash-can',
          canShow: true,
        },
      },
      deal: {
        publish: {
          name: 'publish',
          translation: 'admin-panel.menus.item.publish',
          icon: 'fa-regular fa-cloud-arrow-up',
          canShow: this.canPublishAll(list, entityType),
        },
        unpublish: {
          name: 'unpublish',
          translation: 'admin-panel.menus.item.unpublish',
          icon: 'fa-regular fa-cloud-xmark',
          canShow: this.canUnpublishAll(list, entityType),
        },
        delete: {
          name: 'delete',
          translation: 'admin-panel.menus.item.delete',
          icon: 'fa-regular fa-trash-can',
          canShow: true,
        },
      },
      handout: {
        delete: {
          name: 'delete',
          translation: 'admin-panel.menus.item.delete',
          icon: 'fa-regular fa-trash-can',
          canShow: true,
        },
      },
      communityuser: {
        delete: {
          name: 'delete',
          translation: 'admin-panel.menus.item.remove',
          icon: '',
          canShow: this.canDeleteMultiple(list),
        },
      },
      speaker: {
        delete: {
          name: 'delete',
          translation: 'admin-panel.menus.item.delete',
          icon: 'fa-regular fa-trash-can',
          canShow: true,
        },
      },
      exhibitorvideo: {
        delete: {
          name: 'delete',
          translation: 'admin-panel.menus.item.delete',
          icon: 'fa-regular fa-trash-can',
          canShow: true,
        },
      },
      order: {
        delete: {
          name: 'delete',
          translation: 'admin-panel.menus.item.delete',
          icon: 'fa-regular fa-trash-can',
          canShow: true,
        },
      },
      session: {
        delete: {
          name: 'delete',
          translation: 'admin-panel.menus.item.delete',
          icon: 'fa-regular fa-trash-can',
          canShow: true,
        },
      },
    };
  }

  static canPublishAll(list: Data[], entityType?: string): boolean {
    if (list) {
      const selected = list.filter((selectedItem: Data) => {
        const itemStatus = this.getItemStatus(selectedItem, entityType) as { status: string };
        return itemStatus.status === this.statusOptions.published.status;
      });
      return selected.length === 0;
    }
    return false;
  }

  static canUnpublishAll(list: Data[], entityType?: string): boolean {
    if (list) {
      const selected = list.filter((selectedItem: Data) => {
        const itemStatus = this.getItemStatus(selectedItem, entityType) as { status: string };
        return itemStatus.status !== this.statusOptions.published.status;
      });
      return selected.length === 0;
    }
    return false;
  }

  static canDeleteMultiple(list: Data[]): boolean {
    if (list) {
      const selected = list.filter((selectedItem: Data) => {
        const item = selectedItem as unknown as CompanyUserRole;
        if (item && item.role) {
          return item.role.code === CompanyRoleEnum.OWNER;
        }
        return true;
      });
      return selected.length === 0;
    }
    return false;
  }

  static getItemStatus(item: Data, entityType: string | undefined): object {
    let result = {};
    let publicationTime: string | number | object | null = null;
    let endPublicationTime: string | number | true | object | null = null;
    switch (entityType) {
      case EntityType.PRODUCT:
      case EntityType.LARGE_PRODUCT:
      case EntityType.BOAT_PRODUCT:
        publicationTime = item.publicationTime
          ? getUnixTime(new Date(item.publicationTime as unknown as string))
          : null;
        endPublicationTime = item.endPublicationTime
          ? getUnixTime(new Date(item.endPublicationTime as unknown as string))
          : null;
        break;

      case EntityType.DEAL:
        publicationTime = item.startTime
          ? getUnixTime(new Date(item.startTime as unknown as string))
          : null;
        endPublicationTime = item.endTime
          ? getUnixTime(new Date(item.endTime as unknown as string))
          : null;
        break;

      default:
        break;
    }

    const today = DateTimeHelper.currentTimestamp;
    if ((!publicationTime && !endPublicationTime) || !publicationTime) {
      result = this.statusOptions.unpublished;
    } else if (publicationTime) {
      if (publicationTime <= today) {
        if (endPublicationTime && endPublicationTime <= today) {
          result = this.statusOptions.unpublished;
        } else if (endPublicationTime && endPublicationTime > today) {
          result = this.statusOptions.published;
        } else if (!endPublicationTime) {
          result = this.statusOptions.published;
        }
      } else if (publicationTime >= today) {
        result = this.statusOptions.scheduled;
      }
    }
    return result;
  }
}
