


























































































import { Component, Prop, Watch } from 'vue-property-decorator';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import ProgramEntitySearchWidgetHelper
  from '@/utils/helpers/widgets/ProgramEntitySearchWidgetHelper';
import { State } from 'vuex-class';
import Session from '@/models/graphql/Session';
import ProgramSessionItemComponent from '@/components/program/ProgramSessionItemComponent.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { isSameDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import Variant from '@/utils/enums/Variant';

@Component({
  components: {
    FontAwesomeComponent,
    PillWidget,
    ProgramSessionItemComponent,
  },
})
export default class ProgramCategoryItemComponent extends BreakpointWrapper {
  @Prop({
    required: false,
    default: null,
  })
  private readonly uid!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly name!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly description!: string;

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly sessions!: Session[];

  @Prop({
    required: false,
    default: null,
  })
  private readonly selectedSession!: string | null;

  @Prop({
    required: false,
    default: false,
  })
  private readonly expand!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private readonly displayRecommendedBadge!: boolean;

  @State
  private selectedTzName!: string;

  @State
  private dateLocale!: Locale;

  private showAllDesc = false;

  private showMore = false;

  private isCollapsed = !(this.sessions.length === 1 || this.expand);

  private variantEnum = Variant;

  private get startAndEndSessionTime(): { start: number | null; end: number | null } {
    if (this.sessions.length === 0) {
      return {
        start: null,
        end: null,
      };
    }

    const start = Math.min(...this.sessions.map((session) => session.startTimestamp as number));
    const end = Math.max(...this.sessions.filter((session) => isSameDay(
      utcToZonedTime(this.sessions[0].startTimestamp as number * 1000, this.selectedTzName),
      utcToZonedTime(session.endTimestamp as number * 1000, this.selectedTzName),
    ))
      .map((session) => session.endTimestamp as number));
    return {
      start,
      end,
    };
  }

  private get timeInterval(): string {
    const parts: string[] = [];
    const {
      start,
      end,
    } = this.startAndEndSessionTime;
    if (start) {
      parts.push(ProgramEntitySearchWidgetHelper.formatTime(start, this.selectedTzName, this.dateLocale, 'h:mm a'));
    }
    if (end && start && end > start) {
      parts.push(ProgramEntitySearchWidgetHelper.formatTime(end, this.selectedTzName, this.dateLocale, 'h:mm a'));
    }
    return parts.join(' – ');
  }

  @Watch('windowWidth', {
    immediate: true,
    deep: true,
  })
  private toggleShowMore(): void {
    this.$nextTick(() => {
      if (this.$refs.content) {
        this.showMore = ((this.$refs.content as HTMLParagraphElement).scrollHeight / 20) > 3;
      }
    });
  }
}
