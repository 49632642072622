import { render, staticRenderFns } from "./LivePollPostComponent.vue?vue&type=template&id=0214f275&scoped=true&"
import script from "./LivePollPostComponent.vue?vue&type=script&lang=ts&"
export * from "./LivePollPostComponent.vue?vue&type=script&lang=ts&"
import style0 from "../assets/styles/LivePollPostComponent.scss?vue&type=style&index=0&id=0214f275&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0214f275",
  null
  
)

export default component.exports