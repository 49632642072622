









































































































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import Poll from '@/models/graphql/Poll';
import PollItemComponent from '@/components/poll/PollItemComponent.vue';
import PollDurationModal from '@/components/modals/PollDurationModal.vue';
import PollPresentLiveModal from '@/components/modals/PollPresentLiveModal.vue';
import PollCreateEditModal from '@/components/modals/PollCreateEditModal.vue';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import ButtonComponent from '@/components/ButtonComponent.vue';
import PollAnswer from '@/models/graphql/PollAnswer';
import CommunityUser from '@/models/graphql/CommunityUser';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';

@Component({
  components: {
    ConfirmModal,
    FontAwesomeComponent,
    ButtonComponent,
    IllustrationComponent,
    LoadingSpinnerComponent,
    PollCreateEditModal,
    PollPresentLiveModal,
    PollDurationModal,
    PollItemComponent,
    Splide,
    SplideSlide,
  },
})
export default class PollListComponent extends Vue {
  @Prop({ required: true })
  private readonly polls!: Poll[];

  @Prop({
    required: false,
    default: null,
  })
  private sessionUid!: string;

  @Prop({
    required: false,
    default: 0,
  })
  private triggerLoading!: number;

  @Prop({
    required: false,
    default: false,
  })
  private hideActions!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private isSlideMode!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private canCreate!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private isModerator!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private readonly teleportMenu!: string | null;

  @Prop({
    required: false,
    default: false,
  })
  private readonly stopSlideLoading!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private authUser!: CommunityUser;

  private selectedPoll: Poll | null = null;

  private presentationLink: string | null = null;

  private uniqueId = +new Date();

  private pollListId = `poll-list-${this.uniqueId}`;

  private createEditModalId = `poll-create-edit-modal${this.uniqueId}`;

  private durationModalId = `poll-duration-modal${this.uniqueId}`;

  private presentLiveModalId = `poll-present-modal${this.uniqueId}`;

  private IllustrationType = IllustrationType;

  private isAClone = false;

  private currentSlideIndex = 0;

  private pollToReset: string | null = null;

  private get slideOptions(): object {
    return {
      type: 'slide',
      rewind: true,
      autoWidth: true,
      pagination: false,
      lazyLoad: true,
      arrows: true,
      gap: '0',
      start: this.currentSlideIndex,
      perMove: 1,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private syncSlidePosition(splide: object, newIndex: number, prevIndex: number, destIndex: string): void {
    this.currentSlideIndex = newIndex;
    this.selectedPoll = this.polls[newIndex];
  }

  private nextClick(): void {
    const arrowNextEl = this.$el
      .querySelector(`#${this.pollListId} .splide__arrow--next`) as HTMLElement;
    if (arrowNextEl) {
      arrowNextEl.click();
    }
  }

  private prevClick(): void {
    const arrowPrevEl = this.$el
      .querySelector(`#${this.pollListId} .splide__arrow--prev`) as HTMLElement;
    if (arrowPrevEl) {
      arrowPrevEl.click();
    }
  }

  @Watch('triggerLoading', { immediate: true })
  private reset(): void {
    const infiniteLoading = (this.$refs.infiniteLoading as unknown as Vue);
    this.$emit('on-reset-states');
    if (infiniteLoading) {
      infiniteLoading.$emit('$InfiniteLoading:reset');
    } else if (this.isSlideMode) {
      this.$emit('on-load-polls');
    }
  }

  private infiniteHandler(): void {
    this.$emit('on-load-polls', this.$refs.infiniteLoading as unknown as Vue);
  }

  private onEdit(uid: string): void {
    const poll = this.polls.find((p) => p.uid === uid);
    this.isAClone = false;
    if (poll) {
      this.selectedPoll = poll;
      this.$bvModal.show(this.createEditModalId);
    }
  }

  private onPublish(uid: string): void {
    const poll = this.polls.find((p) => p.uid === uid);
    if (poll) {
      this.selectedPoll = poll;
      this.$bvModal.show(this.durationModalId);
    }
  }

  private onClose(uid: string): void {
    const poll = this.polls.find((p) => p.uid === uid);
    if (poll) {
      this.$emit('on-close-poll', poll);
    }
  }

  private onReset(uid: string): void {
    this.pollToReset = uid;
    setTimeout(() => {
      this.$bvModal.show(`reset-poll-modal-${uid}`);
    }, 100);
  }

  private onConfirmReset(): void {
    const poll = this.polls.find((p) => p.uid === this.pollToReset);
    if (poll) {
      this.$emit('on-reset', poll);
    }
    this.pollToReset = null;
  }

  private onReopen(uid: string): void {
    const poll = this.polls.find((p) => p.uid === uid);
    if (poll) {
      this.selectedPoll = poll;
      this.$bvModal.show(this.durationModalId);
    }
  }

  private onDelete(uid: string): void {
    const poll = this.polls.find((p) => p.uid === uid);
    if (poll) {
      this.$emit('on-delete-poll', poll);
    }
  }

  private onAnswer(poll: Poll, pollAnswer: PollAnswer): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }

    const localPollIndex = this.polls.findIndex((p) => p.uid === poll.uid);
    if (localPollIndex < 0) {
      return;
    }

    this.$emit('on-answer', {
      poll,
      pollAnswer,
    });
  }

  private onPresent(uid: string): void {
    const poll = this.polls.find((p) => p.uid === uid);
    if (poll) {
      this.presentationLink = this.$router.resolve({
        name: 'live-session-presentation-poll',
        params: { pollId: poll.uid },
      }).href;
      this.$bvModal.show(this.presentLiveModalId);
    }
  }

  private onCreateEdit(pollUid: string, sessionId: string, parentSessionId: string): void {
    this.$emit('on-create-edit', pollUid, sessionId, parentSessionId);
    this.isAClone = false;
  }

  private onCreate(): void {
    this.selectedPoll = null;
    this.isAClone = false;
    this.$bvModal.show(this.createEditModalId);
  }

  private onClone(uid: string): void {
    const toBeClonePoll = this.polls.find((poll) => poll.uid === uid);
    this.isAClone = true;
    if (toBeClonePoll) {
      this.selectedPoll = toBeClonePoll;
      this.$bvModal.show(this.createEditModalId);
    }
  }
}
