














































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { BModal } from 'bootstrap-vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import ButtonComponent from '@/components/ButtonComponent.vue';
import BadgeComponent from '@/components/BadgeComponent.vue';
import EditFileResourceModal from '@/components/modals/EditFileResourceModal.vue';
import Community from '@/models/graphql/Community';
import UploadFileHandler from '@/utils/UploadFileHandler';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ToastComponent from '@/components/ToastComponent.vue';
import ToastActionParams from '@/utils/types/ToastActionParams';
import ToastActionType from '@/utils/enums/ToastActionType';
import FileResource from '@/models/graphql/FileResource';
import PillComponent from '@/components/pill/PillComponent.vue';
import Exhibitor from '@/models/graphql/Exhibitor';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import SlotTypeEnum from '@/utils/enums/SlotTypeEnum';
import VideoComponent from '@/components/VideoComponent.vue';

const fileResourceStore = namespace('FileResourceStore');
const toastStore = namespace('ToastStore');
const salesPackageLimitStore = namespace('SalesPackageLimitStore');
const permissionManagerStore = namespace('PermissionManagerStore');

@Component({
  data(): object {
    return {
      uid: '',
      logo: '',
      newLogo: '',
      banner: '',
      newBanner: '',
      urlUploadedImgProfile: '',
      uploadingBanner: false,
      uploadingLogo: false,
      bannerPayloadTooLarge: false,
      logoPayloadTooLarge: false,
    };
  },
  components: {
    VideoComponent,
    AvatarSoloWidget,
    ToastComponent,
    ButtonIconComponent,
    EditFileResourceModal,
    BadgeComponent,
    ButtonComponent,
    PillComponent,
  },
})
export default class ProfileCompanyComponent extends BreakpointWrapper {
  @Prop({ required: true })
  private company!: Exhibitor;

  @Getter
  private community!: Community;

  @fileResourceStore.Action
  private createLogoFileResourceForExhibitor!: (data: {
    uploadToken: string;
    companyUid: string;
  }) => Promise<FileResource | undefined>;

  @fileResourceStore.Action
  private createBannerFileResourceForExhibitor!: (data: {
    uploadToken: string;
    companyUid: string;
  }) => Promise<FileResource | undefined>;

  @fileResourceStore.Action
  private deleteFileResource!: (uid: string) => Promise<FileResource | undefined>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @salesPackageLimitStore.Action
  private filterSlots!: (payload: {exhibitorUid: string; code: string}) => Promise<{ type: string; limit: number }[]>;

  @permissionManagerStore.Getter
  private canManageCompanyContent!: (companyUid: string) => boolean;

  private dataNotSaved = false;

  private canEditBanner = false;

  private canEditLogo = false;

  private get canManageCompanyProfile(): boolean {
    return this.canManageCompanyContent(this.company.uid);
  }

  private get coverImagePath(): string {
    if (this.company
      && this.company.bannerFileResource
      && this.company.bannerFileResource.path) {
      return FileResourceHelper.getFullPath(this.company.bannerFileResource, 'w1110');
    }
    return '';
  }

  private get profileImagePath(): string {
    if (this.company
      && this.company.logoFileResource
      && this.company.logoFileResource.path) {
      return FileResourceHelper.getFullPath(this.company.logoFileResource, 'w136');
    }
    return '';
  }

  created(): void {
    this.filterSlots({
      exhibitorUid: this.company.uid,
      code: SlotTypeEnum.BANNER,
    }).then((response) => {
      if (response.length > 0) {
        this.canEditBanner = response[0].limit > 0 || response[0].limit === -1;
      }
    });

    this.filterSlots({
      exhibitorUid: this.company.uid,
      code: SlotTypeEnum.LOGO,
    }).then((response) => {
      if (response.length > 0) {
        this.canEditLogo = response[0].limit > 0 || response[0].limit === -1;
      }
    });
  }

  mounted(): void {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  }

  // eslint-disable-next-line class-methods-use-this
  beforeWindowUnload(e: Event): void {
    if (this.dataNotSaved) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = false;
    }
  }

  @Watch('company.logoFileResource')
  @Watch('company.bannerFileResource')
  @Watch('company')
  setFormUser(): void {
    if (this.company) {
      this.$data.uid = this.company.uid;
      // eslint-disable-next-line no-underscore-dangle
      this.$data.logo = this.company.logoFileResource?.path;
      this.$data.banner = this.company.bannerFileResource?.path;
    }
  }

  onLocalProfileSave(deleteBefore: boolean): void {
    this.$data.uploadingLogo = true;
    this.$data.logo = (this.$refs.editorCompanyLogo as EditFileResourceModal).showImage();
    this.onProfileSave(deleteBefore);
  }

  onLocalBannerSave(deleteBefore: boolean): void {
    this.$data.uploadingBanner = true;
    this.$data.banner = (this.$refs.editorCompanyBanner as EditFileResourceModal).showImage();
    this.onCoverSave(deleteBefore);
  }

  onDeleteBannerImg(showToast = true): void {
    if (this.company
      && this.company.bannerFileResource
      && this.company.bannerFileResource.uid) {
      this.deleteFileResource(this.company.bannerFileResource.uid);
      if (!this.$data.uploadingBanner) {
        this.company.bannerFileResource = undefined;
        this.$data.newBanner = '';
        this.$bvModal.hide('edit-company-banner');
      }
      if (showToast) {
        this.addNewAction(
          { type: ToastActionType.REMOVE_BANNER },
        );
      }
    }
  }

  onDeleteProfileImg(showToast = true): void {
    if (this.company
      && this.company.logoFileResource
      && this.company.logoFileResource.uid) {
      this.deleteFileResource(this.company.logoFileResource.uid);
      if (!this.$data.uploadingLogo) {
        this.company.logoFileResource = undefined;
        this.$data.newLogo = '';
        this.$bvModal.hide('edit-company-logo');
      }
      if (showToast) {
        this.addNewAction(
          { type: ToastActionType.REMOVE_LOGO },
        );
      }
    }
  }

  async onProfileSave(deleteBefore: boolean): Promise<void> {
    if (this.community.code) {
      UploadFileHandler.uploadFileResourceHandler({
        base64Path: this.$data.logo,
        fileName: `avatar_${this.company.uid}.jpeg`,
        schemaCode: this.community.code,
      }).then((request) => {
        // eslint-disable-next-line no-param-reassign
        request.onload = () => {
          if (request?.status === 413) {
            this.$data.avatarPayloadTooLarge = true;
            this.$data.uploadingLogo = false;
          } else {
            this.$data.avatarPayloadTooLarge = false;
            if (deleteBefore) {
              this.onDeleteProfileImg(false);
            }
            const uploadToken = JSON.parse(request.response)?.uploadToken;
            if (uploadToken) {
              this.createLogoFileResourceForExhibitor({ uploadToken, companyUid: this.company.uid })
                .then((response) => {
                  if (response && response.uid) {
                    this.$data.newLogo = FileResourceHelper.getFullPath(this.$data.logo as unknown as FileResource, 'w136');
                    this.company.logoFileResource = response;
                    this.$data.uploadingLogo = false;
                    this.$bvModal.hide('edit-company-logo');
                    this.addNewAction(
                      { type: ToastActionType.UPDATE_LOGO },
                    );
                  } else {
                    this.$data.uploadingLogo = false;
                    this.$bvModal.hide('edit-company-logo');
                    this.addNewAction(
                      { type: ToastActionType.UPDATE_LOGO_ERROR },
                    );
                  }
                });
            }
          }
        };
      });
    }
  }

  async onCoverSave(deleteBefore: boolean): Promise<void> {
    if (this.community.code) {
      UploadFileHandler.uploadFileResourceHandler(
        {
          base64Path: this.$data.banner,
          fileName: `banner_${this.company.uid}.jpeg`,
          schemaCode: this.community.code,
        },
      ).then((request) => {
        // eslint-disable-next-line no-param-reassign
        request.onload = () => {
          if (request?.status === 413) {
            this.$data.bannerPayloadTooLarge = true;
            this.$data.uploadingBanner = false;
          } else {
            this.$data.bannerPayloadTooLarge = false;
            if (deleteBefore) {
              this.onDeleteBannerImg(false);
            }
            const uploadToken = JSON.parse(request.response)?.uploadToken;
            if (uploadToken) {
              this.createBannerFileResourceForExhibitor({
                uploadToken,
                companyUid: this.company.uid,
              }).then((response) => {
                if (response && response.uid) {
                  this.$data.newBanner = FileResourceHelper.getFullPath(this.$data.banner as unknown as FileResource, 'w1110');
                  this.company.bannerFileResource = response;
                  this.$data.uploadingBanner = false;
                  this.$bvModal.hide('edit-company-banner');
                  this.addNewAction(
                    { type: ToastActionType.UPDATE_BANNER },
                  );
                } else {
                  this.$data.uploadingBanner = false;
                  this.$bvModal.hide('edit-company-banner');
                  this.addNewAction(
                    { type: ToastActionType.UPDATE_BANNER_ERROR },
                  );
                }
              });
            }
          }
        };
      });
    }
  }

  private initDataNotSaved(): void {
    this.dataNotSaved = true;
  }

  private openModal(): void {
    ((this.$refs.editorCompanyLogo as EditFileResourceModal).$children[0] as BModal).show();
  }
}
