export default {
  product: {
    query: `query productsBrowser(%declaration% $filter: _ProductFilter) {
                product(%useDeclaration% filter: $filter) {
                      id
                      uid
                      name
                      description
                      featured
                      url
                      videoUrl
                      dimensions
                      manufacturer
                      model
                      price
                      serie
                      serialNumber
                      publicationTime
                      endPublicationTime
                      _translations {
                        locale
                        values {
                          key
                          value
                        }
                      }
                      exhibitor {
                        uid
                        name
                      }
                      images(orderBy: [id_asc]) {
                        uid
                        fullFileResource {
                          uid
                          schemaCode
                          path
                          fileName
                          fileType
                        }
                        thumbFileResource {
                          uid
                          schemaCode
                          path
                          fileName
                          fileType
                        }
                      }
                      handouts {
                        uid
                        name
                        handoutFileResource {
                          uid
                          schemaCode
                          path
                          fileName
                          fileType
                        }
                      }
                      categories {
                        id
                        uid
                        name
                        domain
                        displaySequence
                        contexts {
                          uid
                          domain
                          context
                          editable
                        }
                      }
                      deals{
                        uid
                        name
                        startTime
                        endTime
                      }
                }
            }`,
    count: `query productsBrowserCount($filter: _ProductFilter) {
                product(filter: $filter) {
                      _count
                }
            }`,
  },
  largeproduct: {
    query: `query largeProductsBrowser(%declaration% $filter: _LargeProductFilter) {
                largeProduct(%useDeclaration% filter: $filter) {
                      id
                      uid
                      name
                      description
                      featured
                      url
                      videoUrl
                      dimensions
                      manufacturer
                      model
                      price
                      serie
                      serialNumber
                      publicationTime
                      endPublicationTime
                      _translations {
                        locale
                        values {
                          key
                          value
                        }
                      }
                      exhibitor {
                        uid
                        name
                      }
                      images(orderBy: [id_asc]) {
                        uid
                        fullFileResource {
                          uid
                          schemaCode
                          path
                          fileName
                          fileType
                        }
                        thumbFileResource {
                          uid
                          schemaCode
                          path
                          fileName
                          fileType
                        }
                      }
                      handouts {
                        uid
                        name
                        handoutFileResource {
                          uid
                          schemaCode
                          path
                          fileName
                          fileType
                        }
                      }
                      categories {
                        id
                        uid
                        name
                        domain
                        displaySequence
                        contexts {
                          uid
                          domain
                          context
                          editable
                        }
                      }
                      deals{
                        uid
                        name
                        startTime
                        endTime
                      }
                }
            }`,
    count: `query largeProductsBrowserCount($filter: _LargeProductFilter) {
                largeProduct(filter: $filter) {
                      _count
                }
            }`,
  },
  boatproduct: {
    query: `query boatProductsBrowser(%declaration% $filter: _LargeProductFilter) {
                largeProduct(%useDeclaration% filter: $filter) {
                      id
                      uid
                      name
                      description
                      featured
                      videoUrl
                      url
                      dimensions
                      manufacturer
                      model
                      price
                      serie
                      serialNumber
                      publicationTime
                      endPublicationTime
                      _translations {
                        locale
                        values {
                          key
                          value
                        }
                      }
                      exhibitor {
                        uid
                        name
                      }
                      booths {
                        uid
                        number
                      }
                      images(orderBy: [id_asc]) {
                        uid
                        fullFileResource {
                          uid
                          schemaCode
                          path
                          fileName
                          fileType
                        }
                      }
                      handouts {
                        uid
                        name
                        handoutFileResource {
                          uid
                          schemaCode
                          path
                          fileName
                          fileType
                        }
                      }
                      boothsOfEdition: _boothsOfEdition {
                        uid
                        id
                        schemaCode
                        number
                      }
                      extraProperties {
                        uid
                        key
                        value
                        config {
                          uid
                          key
                          valueType
                          isMandatory
                        }
                      }
                      categories {
                        id
                        uid
                        name
                        domain
                        displaySequence
                        contexts {
                          uid
                          domain
                          context
                          editable
                        }
                      }
                }
            }`,
    count: `query boatProductsBrowserCount($filter: _LargeProductFilter) {
                largeProduct(filter: $filter) {
                      _count
                }
            }`,
  },
  deal: {
    query: `query dealsBrowser(%declaration% $filter: _DealFilter) {
                deal(%useDeclaration% filter: $filter) {
                      id
                      uid
                      name
                      content
                      featured
                      startTime
                      endTime
                      _translations {
                        locale
                        values {
                          key
                          value
                        }
                      }
                      exhibitor {
                        uid
                        name
                        logoFileResource {
                          uid
                          schemaCode
                          path
                        }
                      }
                      displayFileResource {
                        uid
                        schemaCode
                        path
                        fileName
                        fileType
                        resourceType
                      }
                      categories {
                        uid
                        name
                        domain
                        displaySequence
                        contexts {
                          uid
                          domain
                          context
                          editable
                        }
                      }
                      product{
                        uid
                        name
                      }
                      largeProduct{
                        uid
                        name
                      }
                }
            }`,
    count: `query dealsBrowserCount($filter: _DealFilter) {
                deal(filter: $filter) {
                      _count
                }
            }`,
  },
  handout: {
    query: `query handoutsBrowser(%declaration% $filter: _HandoutFilter) {
                handout(%useDeclaration% filter: $filter) {
                      id
                      uid
                      name
                      exhibitor {
                        uid
                        name
                        logoFileResource {
                          schemaCode
                          path
                        }
                      }
                      thumbnailFileResource {
                        schemaCode
                        path
                      }
                      handoutFileResource {
                        uid
                        schemaCode
                        path
                        fileName
                        fileType
                      }
                }
            }`,
    count: `query handoutsBrowserCount($filter: _HandoutFilter) {
                handout(filter: $filter) {
                      _count
                }
            }`,
  },
  communityuser: {
    query: `query membersBrowser(%declaration% $filter: _CommunityUserFilter) {
              communityUser(%useDeclaration% filter: $filter) {
                id
                uid
                firstName
                lastName
                name
                jobTitle
                employerName
                email
                pictureFileResource {
                    uid
                    deleted
                    edition {
                        urlName
                    }
                    schemaCode
                    path
                }
                roleInCompany: _roleInCompany(companyUid: "%companyUid%") {
                  uid
                  id
                  schemaCode
                  state
                  moderator
                  canPost
                  handleMeetings
                  manageSales
                  manageContent
                  manageCompanyUserRoles
                  company {
                      uid
                      name
                  }
                  role {
                      uid
                      name
                      code
                  }
                }
              }
            }`,
    count: `query membersBrowserCount($filter: _CommunityUserFilter) {
                communityUser(filter: $filter) {
                  _count
                }
            }`,
  },
  exhibitorvideo: {
    query: `query videosBrowser(%declaration% $filter: _ExhibitorVideoFilter) {
              exhibitorVideo(%useDeclaration% filter: $filter) {
                id
                uid
                name
                url
                thumbnailFileResource {
                  uid
                  path
                  fileName
                  fileType
                  schemaCode
                }
              }
            }`,
    count: `query videosBrowserCount($filter: _ExhibitorVideoFilter) {
                exhibitorVideo(filter: $filter) {
                  _count
                }
            }`,
  },
  order: {
    query: `query ordersBrowser(%declaration% $filter: _OrderFilter) {
              order(%useDeclaration% filter: $filter) {
                id
                uid
                status
                totalPrice
                currency
              }
            }`,
    count: `query ordersBrowserCount($filter: _OrderFilter) {
                order(filter: $filter) {
                  _count
                }
            }`,
  },
  session: {
    query: `query sessionsBrowser(%declaration% $filter: _SessionFilter) {
                session(%useDeclaration% filter: $filter) {
                      id
                      uid
                      name
                      description
                      speakers
                      price
                      startTime
                      endTime
                      _translations {
                        locale
                        values {
                          key
                          value
                        }
                      }
                      exhibitor {
                        uid
                        name
                        logoFileResource {
                          uid
                          schemaCode
                          path
                        }
                      }
                      bannerFileResource {
                        uid
                        schemaCode
                        path
                        fileName
                        fileType
                        resourceType
                      }
                      categories {
                        uid
                        name
                        domain
                        displaySequence
                        contexts {
                          uid
                          domain
                          context
                          editable
                        }
                      }
                }
            }`,
    count: `query sessionsBrowserCount($filter: _SessionFilter) {
                session(filter: $filter) {
                      _count
                }
            }`,
  },
};
