import {
  POLL_USER_ANSWER_VERSION_BASE_FRAGMENT,
} from '@/graphql/_Fragments/PollUserAnswerVersion/Base';

export const POLL_ANSWER_BASE_FRAGMENT = `
  fragment pollAnswerBaseFragment on PollAnswer {
    uid
    id
    title
    answerCount
    displaySequence
    correctAnswer
    pollUserAnswers: _pollUserAnswersByLatestVersion {
      uid
      version {
        ...pollUserAnswerVersionBaseFragment
      }
    }
  }
  ${POLL_USER_ANSWER_VERSION_BASE_FRAGMENT}
`;
