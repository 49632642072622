var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.sessionUid)?_c('div',{staticClass:"session-cms-qa-component container-fluid position-relative h-100 d-flex flex-column"},[(_vm.lonelyComponent)?_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-2"},[_c('h4',{staticClass:"font-weight-bold text-left mb-0"},[_vm._v(" "+_vm._s(_vm.$t('speaker-cms.sessions.qa.title'))+" ")]),(_vm.canModerateOrIsSpeaker)?_c('div',[_c('font-awesome-component',{staticClass:"cursor-pointer",attrs:{"container-classes":"icon-32","icon":"fa-regular fa-ellipsis","icon-classes":"text-neutral-n-8-dark-ink icon-24"},on:{"on-click":function($event){_vm.openedMenu = true}}}),(_vm.openedMenu)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return _vm.openedMenu = false; }),expression:"() => openedMenu = false"}],staticClass:"open-close-qa-menu shadows-md z-index-1"},[_c('dropdown-menu-item',{staticClass:"cursor-pointer font-weight-normal",attrs:{"text":_vm.localMessageGroup && _vm.localMessageGroup.active
            ? ("" + (_vm.$t('speaker-cms.sessions.qa.button.close-questions')))
            : ("" + (_vm.$t('speaker-cms.sessions.qa.button.open-questions')))},on:{"on-click-stop":_vm.onOpenCloseQuestionClick}}),_c('dropdown-menu-item',{staticClass:"cursor-pointer font-weight-normal",attrs:{"text":("" + (_vm.$t('speaker-cms.sessions.qa.button.present')))},on:{"on-click-stop":_vm.onOpenPresentationQna}})],1):_vm._e()],1):_vm._e()]):_vm._e(),(((((!_vm.canModerateOrIsSpeaker
      && (_vm.localMessageGroup === undefined
        || (_vm.localMessageGroup && _vm.localMessageGroup.active)
        || (!_vm.localMessageGroup
          && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_AUTO_OPEN_SESSION_QNA)
          && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_AUTO_OPEN_SESSION_QNA).enabled)))
      || _vm.canModerateOrIsSpeaker) && _vm.lonelyComponent) || _vm.lonelyComponent)
      && (_vm.lonelyComponent && !_vm.isQnaClosed))?_c('div',{staticClass:"py-3"},[_c('message-box-write-component',{attrs:{"autofocus":_vm.autofocus,"is-for-chat":false,"message-to-reply-to":!_vm.lonelyComponent ? _vm.messageToAnswer : null,"placeholder":_vm.$t('speaker-cms.sessions.qa.input-placeholder-client'),"store-context":_vm.storeContext},on:{"on-remove-reply-to-message":function($event){_vm.messageToAnswer = null;},"send-message":_vm.onPostMessageClick,"on-change":_vm.onTextChange}})],1):_vm._e(),_c('div',{staticClass:"w-100 h-100 position-relative d-flex flex-column",class:[
      {'overflow-y-auto': !_vm.lonelyComponent}
    ]},[(_vm.canModerateOrIsSpeaker && !_vm.lonelyComponent)?_c('div',{class:[
        'open-close-qa-section w-100 p-2 d-flex align-items-center justify-content-between',
        {'bg-green-g-1-light-green': _vm.localMessageGroup && _vm.localMessageGroup.active},
        {'bg-red-r-1-light-red': !_vm.localMessageGroup
          || (_vm.localMessageGroup && !_vm.localMessageGroup.active)}
      ]},[_c('p',{class:[
          'm-0',
          {'text-green-g-3-primary-green': _vm.localMessageGroup && _vm.localMessageGroup.active},
          {'text-red-r-3-primary-red': !_vm.localMessageGroup
            || (_vm.localMessageGroup && !_vm.localMessageGroup.active)}
        ]},[_vm._v(" "+_vm._s(_vm.localMessageGroup && _vm.localMessageGroup.active ? ("" + (_vm.$t('speaker-cms.sessions.qa.questions-opened'))) : ("" + (_vm.$t('speaker-cms.sessions.qa.questions-closed'))))+" ")]),_c('font-awesome-component',{staticClass:"cursor-pointer",attrs:{"icon-classes":_vm.localMessageGroup && _vm.localMessageGroup.active
          ? 'text-green-g-3-primary-green icon-16'
          : 'text-red-r-3-primary-red icon-16',"container-classes":"icon-24","icon":"fa-regular fa-ellipsis"},on:{"on-click":function($event){_vm.openedMenu = true}}}),(_vm.openedMenu)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return _vm.openedMenu = false; }),expression:"() => openedMenu = false"}],staticClass:"open-close-qa-menu shadows-md"},[_c('dropdown-menu-item',{staticClass:"cursor-pointer font-weight-normal",attrs:{"text":_vm.localMessageGroup && _vm.localMessageGroup.active
            ? ("" + (_vm.$t('speaker-cms.sessions.qa.button.close-questions')))
            : ("" + (_vm.$t('speaker-cms.sessions.qa.button.open-questions')))},on:{"on-click-stop":_vm.onOpenCloseQuestionClick}}),_c('dropdown-menu-item',{staticClass:"cursor-pointer font-weight-normal",attrs:{"text":("" + (_vm.$t('speaker-cms.sessions.qa.button.present')))},on:{"on-click-stop":_vm.onOpenPresentationQna}})],1):_vm._e()],1):_vm._e(),[_vm._l((_vm.localMessages),function(message){return _c('div',{key:message.uid,class:[
          'w-100 mb-2',
          {'bg-blue-b-1-light-blue': _vm.lonelyComponent && _vm.messageToAnswer && _vm.messageToAnswer.uid === message.uid }
        ]},[_c('message-box-bubble-component',{key:message.uid,class:[
            'p-3',
            {'bg-blue-b-1-light-blue': _vm.lonelyComponent && _vm.messageToAnswer && _vm.messageToAnswer.uid === message.uid }
          ],attrs:{"admin-view":_vm.canModerateOrIsSpeaker,"can-like":false,"can-moderate":_vm.canModerateOrIsSpeaker,"dark-mode":false,"message":_vm.MessageEntity.hydrate(message),"message-group-permission":_vm.messageActionPermission,"no-padding":true,"session-moderators":_vm.selectedGroup && _vm.selectedGroup.moderators
            ? _vm.selectedGroup.moderators
            : [],"show-action":_vm.canModerateOrIsSpeaker,"target-type":_vm.EntityType.SESSION,"target-uid":_vm.sessionUid,"you-are-banned":_vm.yourAreBanned},on:{"on-message-to-reply":_vm.onUpdateMessageToReply,"on-delete-message":_vm.onDeleteQuestion}}),((message.childMessages && message.childMessages.length > 0)
            && (!_vm.lonelyComponent || (_vm.lonelyComponent && (!_vm.messageToAnswer || _vm.messageToAnswer.uid != message.uid))))?_c('div',{staticClass:"message-actions d-flex flex-column"},[_c('message-box-answer-component',{attrs:{"message":message.childMessages[0],"message-to-reply-to":_vm.messageToAnswer,"show-action":_vm.canModerateOrIsSpeaker},on:{"delete":function($event){return _vm.removeChildMessage(message)},"edit":function($event){return _vm.onEditAnswerClick(message)}}})],1):_vm._e(),(_vm.lonelyComponent && (_vm.messageToAnswer && _vm.messageToAnswer.uid === message.uid))?_c('div',{staticClass:"message-actions d-flex flex-column w-100 pr-3"},[_c('input-search-component',{staticClass:"w-100 mb-2",attrs:{"dark-border":true,"debounce-time":0,"default-value":message.childMessages && message.childMessages.length > 0
              ? message.childMessages[0].content
              : '',"hide-magnifying-glass":true,"placeholder":("" + (_vm.$t('chat.content.messages.input-placeholder-qa'))),"rounded":true,"color":"white"},on:{"search-query":_vm.onAnswerChange,"search-query-enter-key":_vm.onAnswerQuestion}}),_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-end column-gap-6 mb-3"},[_c('p',{staticClass:"m-0 font-weight-bold text-neutral-n-6-label cursor-pointer",on:{"click":function($event){_vm.messageToAnswer = null; _vm.tempAnswerText = ''}}},[_vm._v(" "+_vm._s(_vm.$t('speaker-cms.sessions.qa.button.cancel'))+" ")]),_c('p',{class:[
                'm-0 font-weight-bold cursor-pointer',
                {'text-neutral-n-6-label': _vm.tempAnswerText.length === 0},
                {'text-blue-b-3-primary-blue': _vm.tempAnswerText.length > 0} ],on:{"click":function($event){return _vm.onAnswerQuestion({ query: _vm.tempAnswerText })}}},[_vm._v(" "+_vm._s(_vm.$t('speaker-cms.sessions.qa.button.post'))+" ")])])],1):_vm._e()],1)}),(!_vm.lonelyComponent)?_c('infinite-loading',{ref:"infiniteLoading",on:{"infinite":_vm.infiniteHandler},scopedSlots:_vm._u([{key:"spinner",fn:function(){return [_c('loading-spinner-component')]},proxy:true}],null,false,1353017473)},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),(_vm.localMessages.length > 0)?_c('div',{attrs:{"slot":"no-results"},slot:"no-results"}):_c('div',{class:[
            'h-100 w-100 d-flex align-items-center justify-content-center flex-column',
            {'no-result': _vm.isDesktop}
          ],attrs:{"slot":"no-results"},slot:"no-results"},[_c('illustration-component',{attrs:{"illustration-type":_vm.IllustrationType.EMPTY_LIVE_CHAT,"message":_vm.canModerateOrIsSpeaker
              ? ("" + (_vm.$t('speaker-cms.sessions.qa.no-result-hub.subtitle')))
              : ("" + (_vm.$t('speaker-cms.sessions.qa.no-result.subtitle'))),"title":_vm.canModerateOrIsSpeaker
              ? ("" + (_vm.$t('speaker-cms.sessions.qa.no-result-hub.title')))
              : ("" + (_vm.$t('speaker-cms.sessions.qa.no-result.title')))}})],1)]):_vm._e()]],2),(!_vm.lonelyComponent && ((!_vm.canModerateOrIsSpeaker
      && (_vm.localMessageGroup === undefined
        || (_vm.localMessageGroup && _vm.localMessageGroup.active)
        || (!_vm.localMessageGroup
          && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_AUTO_OPEN_SESSION_QNA)
          && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_AUTO_OPEN_SESSION_QNA).enabled)))
      || _vm.canModerateOrIsSpeaker))?_c('div',{staticClass:"p-3 shadows-line-up"},[_c('message-box-write-component',{attrs:{"default-value":_vm.messageToAnswer
        && _vm.messageToAnswer.childMessages
        && _vm.messageToAnswer.childMessages.length
        ? _vm.messageToAnswer.childMessages[0].content
        : '',"is-for-chat":false,"message-to-reply-to":_vm.messageToAnswer,"placeholder":_vm.canModerateOrIsSpeaker
        ? ("" + (_vm.$t('speaker-cms.sessions.qa.input-placeholder-moderator')))
        : ("" + (_vm.$t('speaker-cms.sessions.qa.input-placeholder-client'))),"store-context":_vm.storeContext},on:{"on-remove-reply-to-message":function($event){_vm.messageToAnswer = null;},"send-message":_vm.onPostMessageClick,"on-change":_vm.onTextChange}})],1):_vm._e(),(_vm.lonelyComponent && _vm.localMessages.length === 0 && ((_vm.localMessageGroup && _vm.localMessageGroup.active)
      || (!_vm.localMessageGroup
        && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_AUTO_OPEN_SESSION_QNA)
        && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_AUTO_OPEN_SESSION_QNA).enabled)))?_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"m-0 font-weight-bold text-left text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.$t('speaker-cms.sessions.qa.no-result.title'))+" ")]),_c('p',{staticClass:"m-0 text-left text-left text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.$t('speaker-cms.sessions.qa.no-result.subtitle'))+" ")])]):_vm._e(),(_vm.lonelyComponent && _vm.isQnaClosed && _vm.localMessages.length === 0)?_c('div',{staticClass:"mt-3"},[_c('p',{staticClass:"m-0 text-left text-left text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.$t('speaker-cms.sessions.qa.qa-closed.title'))+" ")])]):_vm._e(),(_vm.lonelyComponent && _vm.showViewAllButton && !_vm.isQnaClosed)?_c('p',{staticClass:"m-0 font-weight-bold text-blue-b-3-primary-blue cursor-pointer",on:{"click":_vm.loadMessages}},[_vm._v(" "+_vm._s(_vm.$t('speaker-cms.sessions.qa.button.view-all'))+" ")]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }