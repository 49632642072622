import { render, staticRenderFns } from "./SessionCardWidget.vue?vue&type=template&id=6a298b73&scoped=true&"
import script from "./SessionCardWidget.vue?vue&type=script&lang=ts&"
export * from "./SessionCardWidget.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/SessionCardWidget.scss?vue&type=style&index=0&id=6a298b73&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a298b73",
  null
  
)

export default component.exports