





































import { Component, Prop } from 'vue-property-decorator';
import UnitNumber from '@/utils/enums/UnitNumber';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component({
  inheritAttrs: false,
})
export default class BigNumberComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'BigNumberWidgetStore';

  @Prop({
    required: false,
    default: false,
  })
  private readonly staticValue!: boolean;

  @Prop({
    required: false,
    default: 0,
  })
  private readonly value!: number;

  @Prop({
    required: false,
    default: 0,
  })
  private readonly dynamicValue!: number;

  @Prop({
    required: false,
    default: false,
    type: Boolean,
  })
  private readonly fullNumber!: boolean;

  @Prop({
    required: false,
    default: '/',
  })
  private readonly route!: string;

  @Prop({
    required: false,
    default: '',
  })
  private readonly text!: string;

  @Prop({
    required: false,
    default: '',
  })
  private readonly prefix!: string;

  @Prop({
    required: false,
    default: '',
  })
  private readonly suffix!: string;

  private get navigateTo(): string {
    if (this.route) {
      let r = this.route;
      const matches = this.route.match(/(%[a-zA-Z-_]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '')
            .trim();
          if (this.$props[prop]) {
            r = r.replaceAll(m, this.$props[prop]);
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        return r;
      }
    }
    return this.route;
  }

  created(): void {
    this.setDataConfig();
  }

  public startAnimation(e: Record<string, string | number | object>): void {
    if ((e.type === 'enter' || e.type === 'progress') && e.percentInView >= 0.2) {
      (this.$refs.bigNumber as unknown as { play: () => {} }).play();
    }
  }

  private pushStats(r: string | null): void {
    if (r) {
      const { name } = this.$router.resolve(r).route;
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code || '',
        '',
        StatLoggerActions.CLICK,
        name || r,
        -1,
        '',
        StatLoggerCategories.BIG_NUMBER,
        this.$i18n.locale,
      );
    }
  }

  private formatNumber = (value: number): string => {
    if (this.fullNumber) {
      return `${this.prefix}${value.toFixed(0)
        .toString()}${this.suffix}`;
    }

    const n = parseInt(value.toFixed(0), 10);
    if (n >= 1e3 && n < 1e6) {
      return `${this.prefix}${+(n / 1e3).toFixed(1)}${UnitNumber.THOUSAND}${this.suffix}`;
    }
    if (n >= 1e6 && n < 1e9) {
      return `${this.prefix}${+(n / 1e6).toFixed(1)}${UnitNumber.MILLION}${this.suffix}`;
    }
    if (n >= 1e9 && n < 1e12) {
      return `${this.prefix}${+(n / 1e9).toFixed(1)}${UnitNumber.BILLION}${this.suffix}`;
    }
    if (n >= 1e12) {
      return `${this.prefix}${+(n / 1e12).toFixed(1)}${UnitNumber.TRILLION}${this.suffix}`;
    }
    return `${this.prefix}${n.toString()}${this.suffix}`;
  };
}
