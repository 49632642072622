







































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import ProgramEntitySearchWidgetHelper
  from '@/utils/helpers/widgets/ProgramEntitySearchWidgetHelper';
import { Getter, namespace, State } from 'vuex-class';
import RouteHelper from '@/utils/helpers/RouteHelper';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { CommunityUserAgendaEntryFilter } from '@/graphql/_Filters/CommunityUserAgendaEntryFilter';
import CommunityUserAgendaEntry from '@/models/graphql/CommunityUserAgendaEntry';
import ManageConflictModal from '@/components/program/ManageConflictModal.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import Community from '@/models/graphql/Community';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import Event from '@/utils/types/Event';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import GUUID from '@/utils/GUUID';
import { getHours } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import ScheduleEvent from '@/models/graphql/ScheduleEvent';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';

const communityUserAgendaEntryStore = namespace('CommunityUserAgendaEntryStore');
/* eslint-disable no-underscore-dangle */
@Component({
  components: {
    ButtonIconComponent,
    ManageConflictModal,
    FontAwesomeComponent,
    ButtonComponent,
  },
})
export default class ProgramEventItemComponent extends BreakpointWrapper {
  @Prop({
    required: false,
    default: null,
  })
  private readonly uid!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly name!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly description!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly url!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly urlLabel!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly startTime!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly startTimestamp!: number;

  @Prop({
    required: false,
    default: null,
  })
  private readonly endTime!: string;

  @Prop({
    required: false,
    default: '',
  })
  private readonly type!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly endTimestamp!: number;

  @Prop({
    required: false,
    default: null,
  })
  private readonly _isInAgenda!: string | null;

  @Prop({
    required: false,
    default: false,
  })
  private readonly _haveConflicts!: boolean;

  @State
  private selectedTzAbbreviation!: string;

  @State
  private selectedTzName!: string;

  @State
  private dateLocale!: Locale;

  @State
  private authUser!: CommunityUser;

  @State
  private community!: Community;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @communityUserAgendaEntryStore.Action
  private addToAgenda!: (
    payload: CommunityUserAgendaEntryFilter,
  ) => Promise<CommunityUserAgendaEntry | undefined>;

  @communityUserAgendaEntryStore.Action
  private removeFromAgenda!: (
    payload: CommunityUserAgendaEntryFilter,
  ) => Promise<CommunityUserAgendaEntry | undefined>;

  @communityUserAgendaEntryStore.Action
  private checkForConflicts!: (
    payload: { currentEntity: string; start: number; end: number },
  ) => Promise<Event[]>;

  private FileResourceHelper = FileResourceHelper;

  private FeatureKeys = FeatureKeys;

  private showAllDesc = false;

  private showMore = false;

  private inAgenda = this._isInAgenda;

  private haveConflict = this._haveConflicts;

  private isAgendaLoading = false;

  private modalId = `manage-conflicts-modal-${GUUID.uuidv4()}`;

  private events: Event[] = [];

  private get selectedDay(): string {
    return ProgramEntitySearchWidgetHelper.formatTime(this.startTimestamp, this.selectedTzName, this.dateLocale, 'yyyy-MM-dd');
  }

  private get selectedHour(): number {
    return getHours(utcToZonedTime(this.startTimestamp * 1000, this.selectedTzName));
  }

  private get timeInterval(): string {
    const parts: string[] = [];
    if (this.startTimestamp) {
      parts.push(ProgramEntitySearchWidgetHelper.formatTime(this.startTimestamp, this.selectedTzName, this.dateLocale, 'h:mm a'));
    }
    if (this.endTimestamp && this.endTimestamp > this.startTimestamp) {
      parts.push(ProgramEntitySearchWidgetHelper.formatTime(this.endTimestamp, this.selectedTzName, this.dateLocale, 'h:mm a'));
    }
    return parts.join(' – ');
  }

  scheduleConflict(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    this.isAgendaLoading = true;
    this.checkForConflicts({
      currentEntity: this.uid,
      start: this.startTimestamp,
      end: this.endTimestamp,
    })
      .then((events) => {
        if (events.length > 0) {
          this.events = events.filter((event) => event.uid !== this.uid);
          this.$bvModal.show(this.modalId);
        } else {
          this.addEventToAgenda();
          this.haveConflict = false;
        }
        this.isAgendaLoading = false;
      });
  }

  addEventToAgenda(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }

    if (!this.inAgenda) {
      const event = AgendaStoreHelper.convertScheduleOfEventsToEvent(this.$props as ScheduleEvent, this.selectedTzAbbreviation);
      this.addToAgenda({
        event,
        userId: this.authUser.uid,
        linkedUserId: event.entityId,
        entityType: event.entityType,
        showToast: true,
      })
        .then((response) => {
          this.inAgenda = response?.uid || '';
          this.$emit('set-is-agenda', this.uid, null, response?.uid);
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code as string,
            event.entityType,
            StatLoggerActions.ADD_TO_AGENDA,
            'addAppointment',
            -1,
            event.entityId,
            StatLoggerCategories.ADD,
            this.$i18n.locale,
          );
        })
        .finally(() => {
          this.isAgendaLoading = false;
        });
    }
  }

  removeSessionFromAgenda(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }

    if (this.inAgenda) {
      const event = AgendaStoreHelper.convertScheduleOfEventsToEvent(this.$props as ScheduleEvent, this.selectedTzAbbreviation);
      this.removeFromAgenda({
        event,
        uid: this.inAgenda,
        showToast: true,
      })
        .then(() => {
          this.inAgenda = null;
          this.$emit('set-is-agenda', this.uid, null, null);
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code as string,
            event.entityType,
            StatLoggerActions.REMOVE_FROM_AGENDA,
            'removeAppointment',
            -1,
            event.entityId,
            StatLoggerCategories.REMOVE,
            this.$i18n.locale,
          );
        })
        .finally(() => {
          this.isAgendaLoading = false;
        });
    }
  }

  private toggleAgenda(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }

    if (this.inAgenda) {
      this.removeSessionFromAgenda();
    } else {
      this.scheduleConflict();
    }
  }

  private handleBaseUrl(): void {
    if (this.url) {
      RouteHelper.universalNavigation(
        this.url,
        this.$route.params,
        this.$router,
        undefined,
        undefined,
      );
    }
  }

  @Watch('windowWidth', {
    immediate: true,
    deep: true,
  })
  private toggleShowMore(): void {
    this.$nextTick(() => {
      if (this.$refs.content) {
        this.showMore = ((this.$refs.content as HTMLParagraphElement).scrollHeight / 20) > 3;
      }
    });
  }
}
