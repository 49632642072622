




















































































































































































































import FileResource from '@/models/graphql/FileResource';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import RecommendationBadgeComponent from '@/components/RecommendationBadgeComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import Category from '@/models/graphql/Category';
import Country from '@/models/graphql/Country';
import ButtonComponent from '@/components/ButtonComponent.vue';
import EditionExhibitorMapping from '@/models/graphql/EditionExhibitorMapping';
import Booth from '@/models/graphql/Booth';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import { Getter } from 'vuex-class';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import EntityType from '@/utils/enums/EntityType';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import VideoComponent from '@/components/VideoComponent.vue';
import * as StringHelper from '@/utils/helpers/StringHelper';

@Component({
  components: {
    VideoComponent,
    ButtonIconComponent,
    FontAwesomeComponent,
    ButtonComponent,
    AvatarSoloWidget,
    RecommendationBadgeComponent,
  },
})
export default class ExhibitorPageHeaderComponent extends BreakpointWrapper {
  @Prop({ required: false, default: () => [] })
  readonly categoriesInContext!: Category[];

  @Prop({ required: false, default: false })
  readonly displayHall!: boolean;

  @Prop({ required: false, default: false })
  readonly displayBooth!: boolean;

  @Prop({ required: false })
  readonly schemaCode!: string;

  @Prop({ required: false, default: null })
  readonly route!: string;

  @Prop({ required: false, default: true })
  readonly displayFollowerCountBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly displayFeaturedBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly displayRecommendedBadge!: boolean;

  @Prop({ required: false, default: '' })
  readonly videoPresentationS3Url!: string;

  @Getter
  protected readonly community!: Community;

  @Getter
  protected readonly authUser!: CommunityUser;

  @Prop({ required: false, default: '' })
  protected readonly id!: string;

  @Prop({ required: false, default: '' })
  protected readonly uid!: string;

  @Prop({ required: false, default: null })
  protected readonly name!: string | null;

  @Prop({ required: false, default: false })
  protected readonly featured!: boolean;

  @Prop({ required: false, default: false })
  protected readonly _isRecommendedForMe!: boolean;

  @Prop({ required: false, default: false })
  protected readonly autoFollow!: boolean;

  @Prop({ required: false, default: null })
  protected readonly _followerCount!: string | null;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Prop({ required: false, default: () => [] })
  private readonly bannerFileResource!: FileResource;

  @Prop({ required: false, default: () => [] })
  private readonly logoFileResource!: FileResource;

  @Prop({ required: false, default: null })
  private readonly country!: Country;

  @Prop({ required: false, default: () => [] })
  private readonly editionMappings!: EditionExhibitorMapping[];

  private showDropDown = false;

  private FeatureKeys = FeatureKeys;

  private showPopup = false;

  protected get communityCode(): string {
    return this.community.code as string;
  }

  private get bannerSrc(): string {
    if (this.bannerFileResource) {
      return `url(${FileResourceHelper.getFullPath(this.bannerFileResource, 'w1110')})`;
    }
    if (this.community.defaultExhibitorBannerFileResource) {
      return `url(${FileResourceHelper.getFullPath(this.community.defaultExhibitorBannerFileResource, 'w1110')})`;
    }
    return 'url(/img/banner/empty.svg)';
  }

  private get logoSrc(): string {
    if (this.logoFileResource) {
      return FileResourceHelper.getFullPath(this.logoFileResource, 'w136');
    }
    if (this.community.defaultExhibitorLogoFileResource) {
      return FileResourceHelper.getFullPath(this.community.defaultExhibitorLogoFileResource, 'w136');
    }
    return '';
  }

  private get categories(): string {
    const categories = '';
    if (this
      && this.categoriesInContext) {
      if (Array.isArray(this.categoriesInContext)
        && (this.categoriesInContext as Category[]).length > 0) {
        const entity = this.categoriesInContext as Category[];
        if (entity) {
          return `${entity.slice(0, 3).map((c) => c.name).join(', ')}
          ${entity.length > 3 ? ` + ${entity.length - 3}` : ''}`;
        }
      }
    }
    return categories;
  }

  private get getBooths(): Booth[] | null {
    if (this.editionMappings && this.editionMappings.length > 0 && this.schemaCode) {
      const mapping = this.editionMappings
        .find((ed) => ed.editionExhibitor && ed.editionExhibitor.schemaCode === this.schemaCode);
      let booths = null;
      if (mapping
        && mapping.editionExhibitor
        && mapping.editionExhibitor.booths
        && mapping.editionExhibitor.booths.length > 0) {
        booths = mapping.editionExhibitor.booths;
        if (this.displayHall && !this.displayBooth) {
          const hasHall = booths
            .findIndex((booth) => booth.exhibitHall && booth.exhibitHall.name);
          const hasMoreHall = booths
            .findIndex((booth, i) => i > 0 && booth.exhibitHall && booth.exhibitHall.name);
          if (hasMoreHall === -1) {
            return hasHall > -1 ? [booths[0]] : null;
          }
          if (hasHall > -1) {
            return booths;
          }
          return null;
        }
      }
      return booths;
    }
    return null;
  }

  protected logStats(category: string, action: string, name: string): void {
    this.$logger.logMatomoStats(
      this.authUser,
      this.communityCode,
      EntityType.EXHIBITOR,
      action,
      name,
      parseInt(this.id, 10),
      this.uid,
      category,
      this.$i18n.locale,
    );
  }

  private onBoothClick(booth: Booth): void {
    this.logStats(StatLoggerCategories.VIEW_ON_MAP, StatLoggerActions.ADD, '');
    const { geozone } = booth;
    if (geozone) {
      this.$router.push(
        // eslint-disable-next-line max-len
        `${this.route.toString().trim()}?edition=${this.schemaCode}&hall=${geozone.exhibitHall?.id}&filters[geozones][0]=${geozone?.id}`,
      );
    }
  }

  private boothDetails(booth: Booth): string {
    let details = '';

    if (this.displayBooth) {
      details = `${this.$t('entity-info.product.booth')} ${booth.number}`;
    }
    if (this.displayHall && booth.exhibitHall?.name) {
      const hallName = StringHelper.formatStringToWords(booth.exhibitHall.name);
      details = this.displayBooth
        ? `${hallName} · ${details}`
        : `${hallName}`;
    }

    return details;
  }
}
