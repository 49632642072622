export default class CmsTableHeaders {
  static getHeaders(breakpoint: { value: string }): object {
    return {
      product: [
        {
          name: 'Name',
          translation: 'admin-panel.headers.name',
          class: [
            { 'product-name-big': breakpoint.value === 'xl' },
            { 'product-name-medium': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'product-name-small': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
        {
          name: 'Translation',
          translation: 'admin-panel.headers.translation',
          class: [
            { 'product-translation-big text-center': breakpoint.value === 'xl' },
            { 'product-translation-medium text-center': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'product-translation-small text-center': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
        {
          name: 'Status',
          translation: 'admin-panel.headers.status',
          class: [
            { 'product-status-big': breakpoint.value === 'xl' },
            { 'product-status-medium': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'product-status-small': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
      ],
      largeproduct: [
        {
          name: 'Name',
          translation: 'admin-panel.headers.name',
          class: [
            { 'product-name-big': breakpoint.value === 'xl' },
            { 'product-name-medium': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'product-name-small': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
        {
          name: 'Translation',
          translation: 'admin-panel.headers.translation',
          class: [
            { 'product-translation-big text-center': breakpoint.value === 'xl' },
            { 'product-translation-medium text-center': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'product-translation-small text-center': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
        {
          name: 'Status',
          translation: 'admin-panel.headers.status',
          class: [
            { 'product-status-big': breakpoint.value === 'xl' },
            { 'product-status-medium': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'product-status-small': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
      ],
      boatproduct: [
        {
          name: 'Name',
          translation: 'admin-panel.headers.name',
          class: [
            { 'product-name-big': breakpoint.value === 'xl' },
            { 'product-name-medium': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'product-name-small': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
        {
          name: 'Translation',
          translation: 'admin-panel.headers.translation',
          class: [
            { 'product-translation-big text-center': breakpoint.value === 'xl' },
            { 'product-translation-medium text-center': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'product-translation-small text-center': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
        {
          name: 'Manufacturer',
          translation: 'admin-panel.headers.manufacturer',
          class: [
            { 'product-manufacturer-big': breakpoint.value === 'xl' },
            { 'product-manufacturer-medium': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'product-manufacturer-small': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
        {
          name: 'Booth',
          translation: 'admin-panel.headers.booth',
          class: [
            { 'product-booth-big': breakpoint.value === 'xl' },
            { 'product-booth-medium': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'product-booth-small': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
      ],
      deal: [
        {
          name: 'Name',
          translation: 'admin-panel.headers.name',
          class: [
            { 'deal-name-big': breakpoint.value === 'xl' },
            { 'deal-name-medium': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'deal-name-small': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
        {
          name: 'Translation',
          translation: 'admin-panel.headers.translation',
          class: [
            { 'product-translation-big text-center': breakpoint.value === 'xl' },
            { 'product-translation-medium text-center': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'product-translation-small text-center': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
        {
          name: 'Status',
          translation: 'admin-panel.headers.status',
          class: [
            { 'deal-status-big': breakpoint.value === 'xl' },
            { 'deal-status-medium': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'deal-status-small': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
      ],
      handout: [
        {
          name: 'Name',
          translation: 'admin-panel.headers.name',
          class: [
            { 'handout-name-big': breakpoint.value === 'xl' },
            { 'handout-name-medium': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'handout-name-small': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
      ],
      communityuser: [
        {
          name: 'Name',
          translation: 'admin-panel.headers.name',
          class: 'member-name',
        },
        {
          name: 'Job Title',
          translation: 'admin-panel.headers.job-title',
          class: 'member-jobtitle',
        },
        {
          name: 'Email',
          translation: 'admin-panel.headers.email',
          class: 'member-email',
        },
      ],
      productselectmodal: [
        {
          name: 'Name',
          translation: 'admin-panel.headers.name',
          class: [
            { 'product-name-big': breakpoint.value === 'xl' },
            { 'product-name-medium': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'product-name-small': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
        {
          name: 'Status',
          translation: 'admin-panel.headers.status',
          class: [
            { 'product-status-big': breakpoint.value === 'xl' },
            { 'product-status-medium': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'product-status-small': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
        {
          name: 'Modified',
          translation: 'admin-panel.headers.modified',
          class: [
            { 'product-modified-big': breakpoint.value === 'xl' },
            { 'product-modified-medium': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'product-modified-small': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
      ],
      speaker: [
        {
          name: 'Name',
          translation: 'admin-panel.headers.name',
          class: [
            { 'handout-name-big': breakpoint.value === 'xl' },
            { 'handout-name-medium': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'handout-name-small': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
      ],
      exhibitorvideo: [
        {
          name: 'Name',
          translation: 'admin-panel.headers.name',
          class: [
            { 'handout-name-big': breakpoint.value === 'xl' },
            { 'handout-name-medium': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'handout-name-small': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
      ],
      order: [
        {
          name: 'Id',
          translation: 'admin-panel.headers.id',
          class: [
            { 'handout-id-big': breakpoint.value === 'xl' },
            { 'handout-id-medium': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'handout-id-small': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
        {
          name: 'Status',
          translation: 'admin-panel.headers.status',
          class: [
            { 'product-status-big': breakpoint.value === 'xl' },
            { 'product-status-medium': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'product-status-small': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
        {
          name: 'Total Price',
          translation: 'admin-panel.headers.price',
          class: [
            { 'handout-price-big': breakpoint.value === 'xl' },
            { 'handout-price-medium': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'handout-price-small': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
      ],
      session: [
        {
          name: 'Name',
          translation: 'admin-panel.headers.name',
          class: [
            { 'session-name-small': breakpoint.value === 'xl' },
            { 'session-name-medium': breakpoint.value === 'md' || breakpoint.value === 'lg' },
            { 'session-name-medium': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
        {
          name: 'Start Time',
          translation: 'admin-panel.headers.startTime',
          class: [
            { 'session-time-medium text-center': breakpoint.value !== 'sm' && breakpoint.value !== 'xs' },
            { 'session-time-medium text-center': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
        {
          name: 'End Time',
          translation: 'admin-panel.headers.endTime',
          class: [
            { 'session-time-medium text-center': breakpoint.value !== 'sm' && breakpoint.value !== 'xs' },
            { 'session-time-medium text-center': breakpoint.value === 'sm' || breakpoint.value === 'xs' },
          ],
        },
      ],
    };
  }
}
