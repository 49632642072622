var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['program-session-item bg-white mx-3 mx-md-4 mb-2', { 'rounded': !_vm.isMobile }, {'desktop': _vm.isDesktop},
           _vm.featured ? 'border-blue-b-3-primary-blue' : 'border-neutral-n-4-line',
           {'active': _vm.selectedSession === _vm.uid},
           _vm.disableLeftRightBorder && !_vm.featured
             ? 'border-left-0 border-right-0 shadows-line-down'
             : _vm.disableLeftRightBorder && _vm.featured && _vm.isMobile ? 'border-top-4 border-bottom-4'
               : _vm.featured ? 'border-4' : 'border-1']},[_c('div',{ref:!_vm.isDesktop ? 'informationElement' : '',staticClass:"text-left cursor-pointer d-flex row-gap-4 column-gap-xl-8 flex-column flex-xl-row px-3 py-4 px-md-4",on:{"click":function($event){return _vm.$emit('on-session-item-click', _vm.uid)}}},[_c('div',{staticClass:"left-col d-flex align-items-start"},[_c('div',{staticClass:"d-flex flex-column"},[(_vm.timeInterval)?_c('p',{class:['m-0 text-neutral-n-7-light-ink font-weight-bold text-nowrap',
                   { isMobile: 'paragraph-3' }]},[_vm._v(" "+_vm._s(_vm.timeInterval)+" ")]):_vm._e(),(_vm.hall)?_c('p',{class:['label medium m-0 text-neutral-n-6-label',
                   _vm.isMobile ? 'label-3' : 'label-2']},[_vm._v(" "+_vm._s(_vm.hall)+" ")]):_vm._e(),(_vm._isRecommendedForMe && _vm.displayRecommendedBadge)?_c('recommendation-badge-component',{staticClass:"w-fit mt-3 mt-xl-4",attrs:{"label":("" + (_vm.$t('actions.for-you'))),"size":_vm.isMobile ? 'md' :'xlg',"variant":"orange-gradient"}}):_vm._e()],1),(!_vm.isDesktop
          && _vm.featureByKey(_vm.FeatureKeys.AGENDA)
          && _vm.featureByKey(_vm.FeatureKeys.AGENDA).enabled)?_c('div',{staticClass:"d-flex column-gap-1 ml-auto"},[(!_vm.isMobile)?_c('button-component',{class:['h-fit', {'invisible': !(_vm.haveConflict && _vm.inAgenda)}],attrs:{"rounded":true,"text":_vm.$t('program.conflict'),"size":"md","variant":"yellow"},on:{"on-click":_vm.scheduleConflict},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-clock","icon-classes":"icon-16"}})]},proxy:true}],null,false,1936101878)}):_c('button-icon-component',{class:['h-fit', {'invisible': !(_vm.haveConflict && _vm.inAgenda)}],attrs:{"shape":"round","size":"sm","variant":"yellow"},on:{"on-click":_vm.scheduleConflict},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-16","icon":"fa-regular fa-clock","icon-classes":"icon-8"}})]},proxy:true}],null,false,2620055486)}),_c('button-icon-component',{staticClass:"h-fit",attrs:{"disabled":_vm.isAgendaLoading,"size":_vm.isMobile ? 'sm' : 'md',"variant":_vm.inAgenda ? 'alt-2' : 'tertiary',"shape":"round"},on:{"on-click":_vm.toggleAgenda},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.isAgendaLoading)?_c('font-awesome-component',{attrs:{"container-classes":_vm.isMobile ? 'icon-16' : 'icon-24',"icon-classes":_vm.isMobile ? 'icon-8' : 'icon-16',"spin":true,"icon":"fa-solid fa-spinner-third"}}):_c('font-awesome-component',{attrs:{"container-classes":_vm.isMobile ? 'icon-16' : 'icon-24',"icon":_vm.inAgenda
                ? 'fa-regular fa-calendar-check'
                : 'fa-regular fa-calendar-plus',"icon-classes":_vm.isMobile ? 'icon-8' : 'icon-16'}})]},proxy:true}],null,false,1566362962)})],1):_vm._e()]),_c('div',{staticClass:"right-col d-flex column-gap-4"},[_c('div',{ref:_vm.isDesktop ? 'informationElement' : '',staticClass:"information d-flex flex-column row-gap-3 row-gap-md-4"},[(_vm.name)?[(_vm.isMobile)?_c('h6',{staticClass:"m-0 text-neutral-n-8-dark-ink font-weight-bold"},[_vm._v(" "+_vm._s(_vm.name)+" ")]):_c('p',{staticClass:"m-0 text-neutral-n-8-dark-ink font-weight-bold"},[_vm._v(" "+_vm._s(_vm.name)+" ")])]:_vm._e(),_c('div',{ref:"categoriesContainer",staticClass:"categories d-flex align-items-center gap-1 gap-md-2 overflow-y-hidden flex-nowrap hide-scrollbar"},[_vm._l((_vm.visibleCategories),function(cat){return _c('pill-widget',{key:cat.uid,staticClass:"border-0",attrs:{"label":cat.name,"size":_vm.isMobile ? 'xxs' : 'sm',"variant":"light-gray"}})}),(_vm.hiddenCategoriesCount > 0)?_c('pill-widget',{staticClass:"border-0",attrs:{"label":("+" + _vm.hiddenCategoriesCount),"size":_vm.isMobile ? 'xxs' : 'sm',"variant":"light-gray"}}):_vm._e()],2),(_vm.description)?_c('p',{directives:[{name:"html-remove",rawName:"v-html-remove",value:(_vm.description),expression:"description"}],class:['label label-2 m-0 text-neutral-n-6-label ellipsis-2',
                   _vm.isMobile ? 'label-3' : 'label-2' ]}):_vm._e(),_c('div',{staticClass:"d-flex flex-column row-gap-2"},[_c('div',{staticClass:"d-flex align-items-center flex-wrap row-gap-2 column-gap-2"},[(_vm.exhibitor)?_c('div',{staticClass:"presented d-flex align-items-center gap-2"},[_c('p',{class:['label medium m-0 text-neutral-n-6-label',
                         _vm.isMobile ? 'label-4' : 'label-3' ]},[_vm._v(" "+_vm._s(_vm.$t('program.presented'))+" ")]),_c('avatar-solo-widget',{attrs:{"icon-classes":("text-neutral-n-6-label " + (_vm.isMobile ? 'icon-8' : 'icon-12')),"size":_vm.isMobile ? '16' : '24',"src":_vm.FileResourceHelper.getFullPath(_vm.exhibitor.logoFileResource, 'w96'),"with-borders":true,"default-icon":"fa-regular fa-buildings"}}),_c('p',{class:['label medium m-0 text-neutral-n-8-dark-ink',
                         _vm.isMobile ? 'label-4' : 'label-3' ]},[_vm._v(" "+_vm._s(_vm.exhibitor.name)+" ")])],1):_vm._e(),(_vm.sponsorRoles.length > 0)?_c('div',{staticClass:"sponsors d-flex align-items-center gap-2"},[_c('p',{class:['label medium m-0 text-neutral-n-6-label',
                         _vm.isMobile ? 'label-4' : 'label-3' ]},[_vm._v(" "+_vm._s(_vm.$t('program.sponsored'))+" ")]),_c('b-avatar-group',{attrs:{"overlap":_vm.sponsorImages.length > 1 ? 0.3 : 0,"size":_vm.isMobile ? '16px' : '24px'}},_vm._l((_vm.sponsorImages.slice(0, 3)),function(s){return _c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom.hover",value:(s.alt),expression:"s.alt",modifiers:{"bottom":true,"hover":true}}],key:s.uid,attrs:{"src":s.src,"text":s.alt}})}),1),(_vm.sponsorImages.length > 3)?_c('p',{class:['label medium m-0 text-neutral-n-6-label',
                         _vm.isMobile ? 'label-4' : 'label-5' ]},[_vm._v(" +"+_vm._s(_vm.sponsorImages.length - 2)+" ")]):_vm._e()],1):_vm._e()]),(_vm.speakers.length > 0)?_c('div',{staticClass:"speakers d-flex align-items-center gap-2 w-100"},[_c('p',{ref:"speakersLabel",staticClass:"label label-3 medium m-0 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.$t('program.speakers'))+" ")]),_c('div',{staticClass:"speakers-container d-flex overflow-hidden text-nowrap w-100"},[_c('span',{staticClass:"label label-3 medium m-0 text-neutral-n-8-dark-ink"},[_vm._v(" "+_vm._s(_vm.displayedSpeakers)+" ")])])]):_vm._e()])],2),(_vm.isDesktop
          && _vm.featureByKey(_vm.FeatureKeys.AGENDA)
          && _vm.featureByKey(_vm.FeatureKeys.AGENDA).enabled)?_c('div',{staticClass:"d-flex column-gap-1"},[_c('button-component',{class:['h-fit', {'invisible': !(_vm.haveConflict && _vm.inAgenda)}],attrs:{"rounded":true,"text":_vm.$t('program.conflict'),"size":"md","variant":"yellow"},on:{"on-click":_vm.scheduleConflict},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-clock","icon-classes":"icon-16"}})]},proxy:true}],null,false,1936101878)}),_c('button-icon-component',{staticClass:"h-fit",attrs:{"disabled":_vm.isAgendaLoading,"variant":_vm.inAgenda ? 'alt-2' : 'tertiary',"shape":"round","size":"md"},on:{"on-click":_vm.toggleAgenda},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.isAgendaLoading)?_c('font-awesome-component',{attrs:{"spin":true,"container-classes":"icon-24","icon":"fa-solid fa-spinner-third"}}):_c('font-awesome-component',{attrs:{"icon":_vm.inAgenda
                ? 'fa-regular fa-calendar-check'
                : 'fa-regular fa-calendar-plus',"container-classes":"icon-24"}})]},proxy:true}],null,false,715880690)})],1):_vm._e()])]),_c('manage-conflict-modal',{attrs:{"entity-id":_vm.uid,"events":_vm.events,"is-in-agenda":_vm.inAgenda,"location":_vm.hall,"modal-id":_vm.modalId,"selected-day":_vm.selectedDay,"selected-hour":_vm.selectedHour,"time":_vm.timeInterval,"title":_vm.name,"entity-type":"Session"},on:{"add-to-agenda":_vm.addSessionToAgenda,"remove-from-agenda":_vm.removeSessionFromAgenda}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }