



















































































































import ExhibitorUrl from '@/models/graphql/ExhibitorUrl';
import UrlType from '@/utils/enums/UrlType';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import MyProfileInputText from '@/components/MyProfileInputText.vue';
import ToastActionType from '@/utils/enums/ToastActionType';
import Exhibitor from '@/models/graphql/Exhibitor';
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import ActionType from '@/utils/enums/ActionType';
import { ExhibitorUrlFilter } from '@/graphql/_Filters/ExhibitorUrlFilter';
import { mixins } from 'vue-class-component';
import SocialUrlWrapper, { Url } from '@/components/wrappers/SocialUrlWrapper';
import SlotTypeEnum from '@/utils/enums/SlotTypeEnum';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

const exhibitorUrlStore = namespace('ExhibitorUrlStore');
const salesPackageLimitStore = namespace('SalesPackageLimitStore');

@Component({
  components: {
    MyProfileInputText,
    FontAwesomeComponent,
  },
})
export default class CompanyContactInformation extends mixins(Vue, SocialUrlWrapper, BreakpointWrapper) {
  @Prop({ required: true })
  private company!: Exhibitor;

  @Prop({ default: false })
  private disabled!: boolean;

  @exhibitorUrlStore.Action(ActionType.FILTER)
  private getExhibitorUrls!: (payload: { filter: ExhibitorUrlFilter }) => void;

  @exhibitorUrlStore.Mutation
  private load!: (loading: boolean) => void;

  @exhibitorUrlStore.Action
  private createExhibitorUrl!: (entity: {
    uid: string;
    exhibitorUrl: ExhibitorUrl;
  }) => Promise<ExhibitorUrl | undefined>;

  @exhibitorUrlStore.Action
  private updateExhibitorUrl!: (exhibitorUrl: ExhibitorUrl) => void;

  @exhibitorUrlStore.Action
  private deleteExhibitorUrl!: (uid: string) => void;

  @exhibitorUrlStore.Getter
  private fetchExhibitorUrls!: ExhibitorUrl[];

  @salesPackageLimitStore.Action
  private filterSlots!: (payload: {exhibitorUid: string; code: string})
    => Promise<{ type: string; limit: number; code: number }[]>;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private urlType = UrlType;

  private updatedPhone = '';

  private updatedEmail = '';

  private defaultWebsite: ExhibitorUrl = {
    uid: '',
    type: this.urlType.WEBSITE,
    name: this.urlType.WEBSITE,
    url: '',
    deleted: false,
  };

  private defaultSocial: ExhibitorUrl = {
    uid: '',
    type: '',
    name: this.urlType.SOCIAL,
    url: '',
    deleted: false,
  };

  private limit = -1;

  private totalCount = 0;

  private get exhibitorWebsites(): ExhibitorUrl[] {
    const websites = this.fetchExhibitorUrls?.filter((url) => {
      if (url.name) {
        return url.name === UrlType.WEBSITE;
      }
      const type = url.type?.toLowerCase();
      return url.type === UrlType.WEBSITE || type === null;
    }) as ExhibitorUrl[];
    return websites?.length > 0 ? [websites[0]] : [this.defaultWebsite];
  }

  private get exhibitorSocials(): ExhibitorUrl[] {
    const socials = this.fetchExhibitorUrls?.filter((url) => {
      if (url.name) {
        return url.name === UrlType.SOCIAL;
      }
      const type = url.type?.toLowerCase();
      return (
        (type === UrlType.SOCIAL
          || type === UrlType.LINKEDIN
          || type === UrlType.INSTAGRAM
          || type === UrlType.FACEBOOK
          || type === UrlType.TWITTER
          || type === UrlType.PINTEREST)
      );
    }) as ExhibitorUrl[];
    return socials?.length > 0 ? socials : [this.defaultSocial];
  }

  private get exhibitorPhone(): ExhibitorUrl {
    return this.fetchExhibitorUrls?.filter((url) => {
      const type = url.type?.toLowerCase();
      return type === UrlType.PHONE;
    })[0] as ExhibitorUrl;
  }

  private get exhibitorEmail(): ExhibitorUrl {
    return this.fetchExhibitorUrls?.filter((url) => {
      const type = url.type?.toLowerCase();
      return type === UrlType.EMAIL;
    })[0] as ExhibitorUrl;
  }

  private get canAddUrl(): boolean {
    if (this.limit !== -1) {
      return (this.limit - this.totalCount) > 0;
    }
    return true;
  }

  created(): void {
    this.getCompanyContactInformation();
    this.filterSlots({
      exhibitorUid: this.company.uid,
      code: SlotTypeEnum.URL_SLOT,
    }).then((response) => {
      if (response && response[0]) {
        this.limit = response[0].limit;
      }
    });
  }

  @Watch('company.uid')
  getCompanyContactInformation(): void {
    if (this.company.uid) {
      this.getExhibitorUrls({
        filter: {
          exhibitor: {
            uid: this.company.uid,
          },
        },
      });
    }
  }

  @Watch('fetchExhibitorUrls', { immediate: true })
  getAllExhibitorUrls(): void {
    this.userContacts.phones = [this.exhibitorPhone];
    this.userContacts.email = this.exhibitorEmail?.url || '';
    this.userContacts.websiteUrls = this.exhibitorWebsites;
    this.userContacts.socialsUrls = this.exhibitorSocials;
    this.totalCount = this.fetchExhibitorUrls ? this.fetchExhibitorUrls.length : 0;
  }

  private initDataNotSaved(): void {
    this.dataNotSaved = true;
  }

  private onFieldChange(urlType: UrlType): void {
    this.dataNotSaved = false;
    switch (urlType) {
      case UrlType.PHONE:
        this.currentSelfContext = 'phone';
        this.updatedPhone = this.updatedPhone !== '' ? this.updatedPhone : this.exhibitorPhone?.url;
        if (this.userContacts.phones[0]?.url === '' || this.updatedPhone !== this.userContacts.phones[0]?.url) {
          const phoneUrl = (this.userContacts.phones as Url[])[0].url;
          if (this.exhibitorPhone) {
            this.updateExhibitorUrl({
              uid: this.exhibitorPhone.uid,
              type: this.urlType.PHONE,
              name: this.urlType.PHONE,
              url: phoneUrl,
              deleted: false,
            });
          } else {
            this.createExhibitorUrl({
              uid: this.company.uid,
              exhibitorUrl: {
                uid: '',
                type: this.urlType.PHONE,
                name: this.urlType.PHONE,
                url: phoneUrl,
                deleted: false,
              },
            });
          }
          this.showToast(ToastActionType.UPDATE_COMPANY_INFORMATION);
          this.updatedPhone = phoneUrl;
        }
        break;
      case UrlType.EMAIL:
        this.currentSelfContext = 'email';
        this.updatedEmail = this.updatedEmail !== '' ? this.updatedEmail : this.exhibitorEmail?.url;
        if (this.userContacts.email === '' || this.updatedEmail !== this.userContacts.email) {
          if (this.exhibitorEmail) {
            this.updateExhibitorUrl({
              uid: this.exhibitorEmail.uid,
              type: this.urlType.EMAIL,
              name: this.urlType.EMAIL,
              url: this.userContacts.email,
              deleted: false,
            });
            this.showToast(ToastActionType.UPDATE_COMPANY_INFORMATION);
          } else {
            this.createExhibitorUrl({
              uid: this.company.uid,
              exhibitorUrl: {
                uid: '',
                type: this.urlType.EMAIL,
                name: this.urlType.EMAIL,
                url: this.userContacts.email,
                deleted: false,
              },
            });
            this.showToast(ToastActionType.UPDATE_COMPANY_INFORMATION);
          }
          this.updatedEmail = this.userContacts.email;
        }
        break;
      default:
        break;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private onUrlChange(url: ExhibitorUrl, urlType: UrlType, urlLocalIndex: number): void {
    this.onUrlInputChange({ ...url, type: urlType }, urlLocalIndex,
      {
        userUrls: this.fetchExhibitorUrls,
        createUserUrl: this.addUrl,
        updateUserUrl: this.updateExhibitorUrl,
      },
      {
        onRemoveUrlClick: this.onRemoveUrlClick,
        showToast: this.showToast,
      });
  }

  private addUrl(url: ExhibitorUrl): void {
    const exhibitorUrl: ExhibitorUrl = {
      uid: '',
      name: url.name,
      type: url.type,
      url: url.url,
    };

    this.load(true);
    this.createExhibitorUrl({ uid: this.company.uid, exhibitorUrl })
      .then(() => this.load(false));
  }

  private onAddUrlClick(urlType: UrlType): void {
    const exhibitorUrl = {
      uid: '',
      name: urlType,
      type: '',
      url: '',
    };

    switch (urlType) {
      case UrlType.WEBSITE:
        (this.userContacts.websiteUrls as Url[]).push(exhibitorUrl);
        break;
      case UrlType.SOCIAL:
        (this.userContacts.socialsUrls as Url[]).push(exhibitorUrl);
        break;
      default:
        break;
    }
    this.totalCount += 1;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onRemoveUrlClick(uid: string, urlType: UrlType, urlIndexLocal: number): void {
    if (uid) {
      this.deleteExhibitorUrl(uid);
      this.showToast(ToastActionType.UPDATE_COMPANY_INFORMATION);
    }

    switch (urlType) {
      case UrlType.WEBSITE:
        if (!this.userContacts.websiteUrls.length) {
          return;
        }
        this.userContacts.websiteUrls.splice(urlIndexLocal, 1);
        if (this.userContacts.websiteUrls.length === 0) {
          this.userContacts.websiteUrls.push(this.defaultWebsite);
        }
        break;
      case UrlType.SOCIAL:
        if (!this.userContacts.socialsUrls.length) {
          return;
        }
        this.userContacts.socialsUrls.splice(urlIndexLocal, 1);
        if (this.userContacts.socialsUrls.length === 0) {
          this.userContacts.socialsUrls.push(this.defaultSocial);
        }
        break;
      default:
        break;
    }
    this.totalCount -= 1;
  }

  private showToast(type: ToastActionType): void {
    this.$emit('on-show-toast', type);
  }
}
